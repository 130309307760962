import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, SecondaryHeading, Text } from '../../foundations/Text'
import React, { useState } from 'react'
import { DesignTokens } from "../../foundations/tokens"
import { useTheme } from "@emotion/react"
import PDFGenerator, { GeneratePDF } from "../../components/PDFGenerator"
import SignatureInput from "../../components/SignatureInput"
import { useHistory } from 'react-router-dom'
import { Checkbox } from '../../foundations/Checkbox'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

export const DrivingSafetyCourseRequestPage: React.FC = () => {

  const theme = useTheme() as DesignTokens
  const history = useHistory()
  const [didAcknowledgeIWillCompleteCourse, setDidAcknowledgeIWillCompleteCourse] = useState(false)
  const [didAcknowledgeIWillPay, setDidAcknowledgeIWillPay] = useState(false)
  const [didAcknowledge, setDidAcknowledge] = useState(false)
  const [didAcknowledgeNotCompleted, setDidAcknowledgeNotCompleted] = useState(false)
  const [didAcknowledgeValidProof, setDidAcknowledgeValidProof] = useState(false)

  // Bringing Citation Data
  const citations = useSelector((state: RootState) => state.citations.value)
  const citationType = citations.map((citation) => citation.citationType)

  // Bring in user name
  const userValues = useSelector((state: RootState) => state.user.value)
  const firstName = userValues.map((name) => name.firstName)
  const lastName = userValues.map((name) => name.lastName)

  // const citationType = "Speeding"
  const fullName = "Nicolas Fisher"

  const handleClick = () => {
    if(didAcknowledge) {
      GeneratePDF("innerContent")
      history.push("/bill")
    }
  }
  
  return (
    <PageLayout> 
      <PDFGenerator genId={"innerContent"}>
      <FlexColumn gap={8}>
        <Heading textAlign="center">
            Driving Safety Course Request
            <br></br><br></br>
        </Heading>
        <Text>
            I, <span style={{ fontWeight: "bold"}}>{firstName} {lastName}</span>, in the above do hereby
            enter my appearance, waive my right to trial, 
            and plea NO CONTEST to the charge of <span style={{ fontWeight: "bold"}}>{citationType}</span>. 
            I further request that the court dismiss this citation by allowing me 
            to complete a Driver Safety Course as provided under *see penal code* 
            of the Texas Transportation Code. I also understand that the Court 
            will enter a finding of guilty,
            but that such finding will be dismissed in a period of ninety (90) days 
            from the date of this request provided that:
        </Text>
        <Checkbox label="NO LATER THAN 90 DAYS FROM THE DATE OF THIS REQUEST, 
                        I will complete a Driver Safety Course approved by 
                        the Texas Education Agency; and provide the “Court Copy” of 
                        the uniform certificate of the Driver Safety Course completion 
                        and a Current Driving Record to the Court." 
                    checked={didAcknowledgeIWillCompleteCourse}
                    onChange={e => setDidAcknowledgeIWillCompleteCourse(!didAcknowledgeIWillCompleteCourse)}
        />
        <Checkbox label="I will pay the cost of *amount* to the Court on the date of my request." 
                    checked={didAcknowledgeIWillPay}
                    onChange={e => setDidAcknowledgeIWillPay(!didAcknowledgeIWillPay)}
        />
        <Text>
            In support of my request, I swear that the following statements are true:
        </Text>
        <Checkbox label="I am not in the process of taking a Driver Safety Course 
                         under *penal code* of the Texas Transportation code." 
                    checked={didAcknowledge}
                    onChange={e => setDidAcknowledge(!didAcknowledge)}
        />
        <Checkbox label="I have not completed a Driver Safety Course for the dismissal of 
                         a traffic violation within the twelve (12) month period preceding 
                         the date of the alleged violation." 
                    checked={didAcknowledgeNotCompleted}
                    onChange={e => setDidAcknowledgeNotCompleted(!didAcknowledgeNotCompleted)}
        />
        <Checkbox label="I have provided the Court a valid copy of my 
                        Texas Driver license and proof of insurance that 
                        has my name listed as a driver on the insurance card." 
                   checked={didAcknowledgeValidProof}
                   onChange={e => setDidAcknowledgeValidProof(!didAcknowledgeValidProof)}
        />
        <SignatureInput style={{ marginTop: '20px' }}/>
        <PrimaryButton disabled={!didAcknowledge} onClick={handleClick}>Next</PrimaryButton>
      </FlexColumn>
      </PDFGenerator>
    </PageLayout>
  )
}