import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, SecondaryHeading, Text } from '../../foundations/Text'
import React, { useEffect } from 'react'
import { DesignTokens } from "../../foundations/tokens"
import { Pill } from "../../foundations/Pill"
import { useTheme } from "@emotion/react"
import { useHistory } from 'react-router-dom'
import PDFGenerator, { GeneratePDF } from "../../components/PDFGenerator"
import SignatureInput from "../../components/SignatureInput"
import { useAuth0 } from '@auth0/auth0-react'
// import { useCitationState, useUserState } from '../../store/data'
import { isEmpty } from 'ramda'
import { useMutation } from 'react-query'
import { queryUser } from '../../api/queries/queryUser.query'

export const GuiltyPleaPage: React.FC = () => {

  const theme = useTheme() as DesignTokens
  const history = useHistory()
  const { isAuthenticated, user } = useAuth0()
  // const {userState, upsertUserState} = useUserState()
  // const { citationState } = useCitationState()
  const getUser = useMutation(queryUser, {
    // onSuccess: data => {
    //   (upsertUserState as any)(data)
    // },
    // onError: console.error
  })

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if(isEmpty(userState))
  //       getUser.mutate({ user })
  //   }
  // }, [isAuthenticated, userState])

  // const citationNumber = citationState?.citationNumber
  // const fullName = userState.firstName + " " + userState.lastName

  const handleClick = () => {
    GeneratePDF("innerContent")
    history.push("/bill")
  }
  
  return (
    <PageLayout> 
        <PDFGenerator genId={"innerContent"}>
      <FlexColumn gap={8}>
          <Heading textAlign="center">
            Guilty
          </Heading>
          <Pill>
            {/* Citation #: <span style={{ fontWeight: "bold"}}>{citationNumber}</span> */}
          </Pill>
          <Text>
            {/* I, <span style={{ fontWeight: "bold"}}>{fullName}</span>, do hereby enter */}
            my appearance on the complaint of the offense.
            <br></br><br></br>
          </Text>
          <Text>
            {/* Wit <span style={{ fontWeight: "bold"}}>{fullName} </span> */}
            charged in Municipal Court cause number given
            above. I have been informed of my right to a jury trial
            and that my signature to this plea of guilty will have
            the same force and effect as a judgment of the Court. I
            do hereby plead guilty to the offense as charged,
            waive my right to a jury trial or hearing by the Court,
            and agree to pay the fine and costs the judge
            assesses. <br></br><br></br>
            <span style={{ color: theme.colors.primary }}>
              I understand that my plea may result in a
              conviction appearing on either a criminal record or a
              driverʼs license record.
            </span> 
            <br></br><br></br>
          </Text>
            <SecondaryHeading textAlign="center">
                SIGN
            </SecondaryHeading>
           <SignatureInput style={{ marginTop: '20px' }}/>
        <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
      </FlexColumn>
        </PDFGenerator>
    </PageLayout>
  )
}