import React from 'react'
import { useForm } from "react-form"
import { Button, Grid, Heading, Spinner, Text } from "theme-ui"
import { Select } from '../foundations/Select'
import { statesAbbr } from '../utils/stateAbbreviations'
import { useInput } from '../utils/useInput'
import { TextInputField } from './ConnectedInputs'
import { queryUserPlateInfo } from "../api/queries/queryUserPlateInfo.query"

export type SearchVehicleInfoProps = {
  onSubmit: (values: any) => void,
  onCancel: () => void,
  defaultValues?: {
    plate: string
    state: string
  }
}

export const SearchVehicleInfo: React.FC<SearchVehicleInfoProps> = ({ onSubmit, onCancel, defaultValues }) => {

  const {
    Form,
    meta: {
      isSubmitting,
      canSubmit,
    },
  } = useForm({
    onSubmit: async (values, { reset }) => {
        const data = await queryUserPlateInfo({
            plate: values['plate'],
            state: statesAbbr.find(usState => usState.label === selectedState.value)?.value as string
        })
      await onSubmit({
        ...data,
        state: statesAbbr.find(usState => usState.label === selectedState.value)?.value
      })
      reset()
    }
  })

  const defaultStateValue = defaultValues?.state
  const defaultStateLabel = statesAbbr.find(statePair => statePair.value === defaultStateValue)?.label
  const selectedState = useInput<string | null>(defaultStateLabel as string)

  return (
    <Form>
      <Grid gap={4}>
        <Heading variant="secondary">Search Vehicle Info</Heading>
        <Text>Give us your plate and state and we&apos;ll get the rest of your vehicle info for you.</Text>
        <TextInputField label="Plate" field="plate" defaultValue={defaultValues?.plate}/>
        <Select
          items={statesAbbr.map(value => value.label)}
          label="State"
          onSelectedItemChange={e => selectedState.setValue(e.selectedItem as string)}
          selectedItem={selectedState.value as string}
        />

        <Grid columns="3fr 1fr">
          <Button sx={{ fontSize: 1 }} type="submit" disabled={!canSubmit}>
            { isSubmitting ? <Spinner strokeWidth={4} size={18} color="white"/> : "Search"}
          </Button>
          <Button sx={{ fontSize: 1 }} type="button" bg="white" color="primary" onClick={onCancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Form>
  )
}