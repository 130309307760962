import config from "../../config"
import { querySignedPutUrl } from "../queries/querySignedPutUrl.query"

interface MutationDeps {
  getAccessTokenSilently: () => Promise<string>
}

type UpsertInsuranceInfoOpts = {
  insuranceCardImage: Blob
}

export const upsertInsuranceInfo = (deps: MutationDeps) => async function (opts: UpsertInsuranceInfoOpts) {
  // upload to S3
  const { url: signedUrl, key } = await querySignedPutUrl()
  await fetch(signedUrl, {
    method: "PUT",
    body: opts.insuranceCardImage
  })

  // continue with command normally
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/upsert-insurance-info`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ insuranceCardImageKey: key })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}