import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react'
import { Text } from './Text'
import { DesignTokens, selectColor, selectSpace } from './tokens'

export type CheckboxProps = {
  label: string,
  checked: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {

  const [hasFocus, setHasFocus] = useState<boolean>(false)

  return (
    <_Root>
      <_Label as="label" onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} hasFocus={hasFocus}>
        <_Box checked={props.checked} />
        <span>{label}</span>
        <HiddenCheckbox {...props} />
      </_Label>
    </_Root>
  )
}

const _Root = styled.div`
  display: flex;
  justify-content: flex-start;
`
const _Label = styled(Text)<{theme?: DesignTokens, hasFocus: boolean}>`
  padding: ${selectSpace(1)};
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  gap: ${selectSpace(3)};
  border: white thin solid;
  border-radius: ${selectSpace(1)};
  ${props => props.hasFocus ? css`border-color: #ddd` : css``}
`
const _Box = styled.div<{ checked: boolean, theme?: DesignTokens }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
  max-width: 20px;
  min-width: 20px;
  background: ${props => props.checked ? selectColor("primary")(props) : 'white'};
  border-radius: 3px;
  border: ${selectColor("primary")} 2px solid;
  transition: all 150ms;
  margin-top: 1px;    
`

const HiddenCheckbox = styled.input`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
HiddenCheckbox.defaultProps = {
  type: "checkbox"
}