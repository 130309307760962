import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface Mode {
  value: Blob;
}

const initialState: Mode = {
  value: new Blob
};

export const driverLicenseModeSlice = createSlice({
  name: "driverLicenseMode",
  initialState,
  reducers: {
		addCurrentData: (state, action) => {
			state.value =  action.payload
		}
  },
});

export const { addCurrentData } = driverLicenseModeSlice.actions;

export default driverLicenseModeSlice.reducer;