import config from "../../config"

export type PlateNumber = string
export type State = string

export interface QueryUserPlateInfoOpts {
  plate: PlateNumber,
  state: State
}
export const queryUserPlateInfo = async function({ plate, state }: QueryUserPlateInfoOpts) {
  const url = `https://us-license-plate-to-vin.p.rapidapi.com/licenseplate?plate=${plate}&state=${state}`
  const fetchOpts: RequestInit = {
    method: "get",
    headers: {
      'x-rapidapi-key': config.rapidapi.key,
      'x-rapidapi-host': config.rapidapi.host
    }
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  return data
}