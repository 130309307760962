import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState, useEffect } from "react";

export type orientation = "p" | "portrait" | "l" | "landscape"
export type format = "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc" 

export const GeneratePDF = async (genId: string, orientation?: orientation, format?: format, w?: number, h?: number) => {

    var data
    var vp = document.getElementById("viewportMeta")?.getAttribute("content");

    document.getElementById("viewportMeta")?.setAttribute("content", "width=800")

    
    await html2canvas(document.querySelector("." + CSS.escape(genId) + "") as HTMLElement).then(async canvas => {
        const url = canvas.toDataURL('image/png')

        const calcW = canvas.width
        const calcH = canvas.height

        var doc = new jsPDF(orientation ?? (calcW > calcH ? 'l' : 'p'), format ?? 'px', [w ?? calcW, h ?? calcH]);

        var height = doc.internal.pageSize.getHeight()
        var width = doc.internal.pageSize.getWidth()

        if (calcW > calcH || orientation === 'l' || orientation === 'landscape') {
            const ratio = canvas.width / canvas.height
            width = ratio * height
        } else {
            const ratio = canvas.height / canvas.width
            height = ratio * width
        }
        

        doc.addImage(url, "PNG", 10, 10, width - 20, height - 20)
        data = doc.output('blob')

        const uri = URL.createObjectURL(data)

        window.open(uri, '_blank')
    }).then(() => {
        document.getElementById("viewportMeta")?.setAttribute("content", vp || "width=device-width, initial-scale=1");
    });

    return data
}

const PDFGenerator = (props: { children: React.ReactNode, genId: string }) => {
    const [key, setKey] = useState(props.genId)

    useEffect(() => {
        setKey(props.genId)
    }, [props])

    return (
        <div className={key}>
            { props.children }
        </div>
    )
}
export default PDFGenerator