import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { createContainer } from "unstated-next"


export enum SafariNoticeState {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED"
}

export const SafariNoticeContainer = createContainer(() => {

  const initSafariNotice = window.localStorage.getItem('safari-notice') as SafariNoticeState | null

  const [safariNotice, setSafariNotice] = useState<SafariNoticeState>(initSafariNotice ?? SafariNoticeState.ENABLED)

  useEffect(() => {
    window.localStorage.setItem('safari-notice', safariNotice)
  }, [safariNotice])

  return {
    safariNotice,
    setSafariNotice
  }

})