import { applyPolyfills, defineCustomElements } from "@microblink/blinkid-in-browser-sdk/ui/loader"
import { Auth0Provider } from "@auth0/auth0-react"
import { store } from "./store"
import { Global, css, ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { ThemeProvider } from "theme-ui"
import { initTracker } from "./utils/analytics"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import * as serviceWorker from "./serviceWorker"
import { App } from "./App"
import config from "./config"
import React from "react"
import ReactDOM from "react-dom"
import { tokens } from "./foundations/tokens"
import { ToastContainer } from "react-toastify"
import ReactPWAInstallProvider from "react-pwa-install";

import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-html5-camera-photo/build/css/index.css"
import { SafariNoticeContainer } from "./context/SafariNotice.container"
import { RunTourContainer } from "./context/RunTour.container"
import { useHistory } from "react-router-dom"

const queryClient = new QueryClient()

// const store = configureStore()

console.log(`Application Context: ${config.env}`)

const globalStyles = css`
  // fonts
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
  // global styles
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  body {
    font-size: 18px;
    /* background: rgb(242, 239, 228); */
  }
  
`

const Auth0ProviderWithHistory = ({ children }: { children: any }) => {
  const history = useHistory();

  const redirectCallback = (appState: any) => {
    console.log('app state')
    console.log(appState)
    // history.push(appState?.returnTo || window.location.origin)
  }

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={`${window.location.origin}/registration`}
      onRedirectCallback={redirectCallback}
      audience={`api.getcheckapp.com`}
    >
      { children }
    </Auth0Provider>
  )
}

initTracker()

const AllProviders: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <RunTourContainer.Provider>
        <SafariNoticeContainer.Provider>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ReactPWAInstallProvider>
            <EmotionThemeProvider theme={tokens}>
              <ThemeProvider theme={tokens}>
                <Global styles={globalStyles} />
                <Auth0ProviderWithHistory>
                  <QueryClientProvider client={queryClient} >
                    <Provider store={store}>
                      <React.StrictMode>
                        {children}
                      </React.StrictMode>
                    </Provider>
                  </QueryClientProvider>
                </Auth0ProviderWithHistory>
              </ThemeProvider>
            </EmotionThemeProvider>
          </ReactPWAInstallProvider>
        </SafariNoticeContainer.Provider>
      </RunTourContainer.Provider>
    </React.Fragment>
  )
}

ReactDOM.render(
  <AllProviders><App /></AllProviders>,
  document.getElementById("root")
);

applyPolyfills().then(() => {
  defineCustomElements()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
