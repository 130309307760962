import React, { useState } from 'react'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { StyledInput } from './Input'
import styled from "@emotion/styled"
import { DesignTokens, selectFontSize, selectSpace } from './tokens'
import { _BaseButton } from "./Buttons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { css } from '@emotion/react'

interface ComboboxProps {
  items: string[]
  selectedItem: string
  onSelectedItemChange: (changes: UseComboboxStateChange<string>) => void,
  placeholder?: string
}

export const Combobox: React.FC<ComboboxProps> = ({ items, selectedItem, onSelectedItemChange, placeholder = "Type here..." }) => {
  const [inputItems, setInputItems] = useState(items)
    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox({
      items: inputItems,
      selectedItem,
      onSelectedItemChange,
      onInputValueChange: ({ inputValue }) => {
        setInputItems(
          items.filter(item =>
            item.toLowerCase().startsWith(inputValue?.toLowerCase() || ""),
          ),
        )
      },
    })

    const _faCaretDown = faCaretDown as IconProp

    return (
      <div>
        <_Combobox {...getComboboxProps()}>
          <_ComboboxInput {...getInputProps()} placeholder={placeholder}/>
          <_ComboboxButton
            type="button"
            {...getToggleButtonProps()}
            aria-label="toggle menu"
          >
            <FontAwesomeIcon icon={_faCaretDown}/>
          </_ComboboxButton>
        </_Combobox>
        <_Menubox {...getMenuProps()}>
          {isOpen && (
            <_MenuboxItems>
              {
                inputItems.map((item, index) => (
                  <_MenuboxItem
                    isActive={highlightedIndex === index}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item}
                  </_MenuboxItem>
                  ))
              }
            </_MenuboxItems>
          )}
        </_Menubox>
      </div>
    )
}

const _Menubox = styled.div`
  position: relative;
`
// const _MenuboxItems = styled.ul`
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
//   overflow: auto;
//   position: absolute;
//   width: 100%;
//   background: white;
//   z-index: 500;
// `

const _MenuboxItems = styled.ul`
  margin-top: ${selectSpace(3)};
  display: flex;
  flex-direction: column;
  border-radius: ${selectSpace(1)};
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
  max-height: ${selectSpace(64)};
  overflow: auto;
  padding: ${selectSpace(3)};
  position: absolute;
  width: 100%;
  background: white;
  z-index: 500;
`

const _MenuboxItem = styled.li<{ theme?: DesignTokens, isActive: boolean }>`
  padding: ${selectSpace(3)};
  border-radius: ${selectSpace(1)};
  list-style: none;
  border: #fff thin solid;
  ${props => props.isActive ? css`
    border-color: #ddd;
    background: #f4f4f4;
  ` : css``}
`

// const _Combobox = styled.label`
//   overflow: hidden;
//   border: #c4c4c4 2px solid;
//   position: relative;
//   display: flex;
// `

const _Combobox = styled.label`
  border-radius: ${selectSpace(2)}; 
  overflow: hidden;
  border: #c4c4c4 2px solid;
  position: relative;
  display: flex;
`

const _ComboboxButton = styled(_BaseButton)`
  font-size: ${selectFontSize(4)};
  padding: ${selectSpace(5)} ${selectSpace(6)};
  outline: none;
  border-radius: 0;
  &:hover, &:focus {
    background: #f4f4f4;
  }
`
const _ComboboxInput = styled(StyledInput)`
  padding: ${selectSpace(6)};
`