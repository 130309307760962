import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
	firstName: string,
  lastName: string,
  email: string
}

export interface UserState {
  value: User[];
}

const initialState: UserState = {
  value: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    create: (state, action: PayloadAction<User>) => {
      state.value.push(action.payload);
    },
  },
});

export const { create } = userSlice.actions;

export default userSlice.reducer;