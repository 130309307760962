import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Description {
  value: string;
}
const initialState: Description = {
  value: '',
};

export const noContestDescriptionSlice = createSlice({
  name: "noContestChoice",
  initialState,
  reducers: {
    addDescription: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
});

export const { addDescription } = noContestDescriptionSlice.actions;

export default noContestDescriptionSlice.reducer;