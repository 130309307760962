import styled from "@emotion/styled"
import React from 'react'

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  objectFit?: "contain" | "cover"
}

export const Image = styled(({ className, style, ...props }: ImageProps) => {
  return (
    <div className={className} style={style}>
      <img {...props} />
    </div>
  )
})`
  height: 100%;
  width: 100%;
  background: #333;
  > img {
    object-fit: ${props => props.objectFit ?? 'contain'};
    height: 100%;
    width: 100%;
  }
`