import { useAuth0 } from '@auth0/auth0-react'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { Spinner } from '@theme-ui/components'
import { createContainer } from "unstated-next"
import { mutationUpsertVehicleInfo } from '../../../../api/mutations/upsertVehicleInfo.mutation'
import { querySelf } from '../../../../api/queries/querySelf.query'
import { SearchVehicleInfo } from '../../../../forms/SearchVehicleInfoForm'
import { ActionCard } from '../../../DashboardPage/components/ActionCard'
import { EditVehicleInformation } from './components/EditVehicleInformation'
import { VehicleInfoView } from './components/VehicleInfoView'

export enum VehicleCompositionMode {
  EDIT = "EDIT",
  VIEW = "VIEW",
  INIT = "INIT",
  LOADING = "LOADING",
  SEARCH = "SEARCH",
}

export const VehicleInfoSectionContainer = createContainer(() => {
  const { getAccessTokenSilently, isLoading: isAuthLoading, isAuthenticated } = useAuth0()
  const [vehicleCompositionMode, setVehicleCompositionMode] = useState<VehicleCompositionMode>(VehicleCompositionMode.VIEW)
  
  let {
    isLoading,
    data,
    refetch
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => toast.error(error?.message)
  })

  const upsertVehicleInfoMutation = useMutation(mutationUpsertVehicleInfo({
    getAccessTokenSilently,
    vehicleId: data?.vehicles?.[0]?.id as string
  }), {
    onSuccess: async () => {
      setVehicleCompositionMode(VehicleCompositionMode.VIEW)
      await refetch()
    },
    onError: (error: any) => {
      toast.error(error?.message)
    }
  })

  const isDataLoading = isAuthLoading || isLoading

  useEffect(() => {
    if (!isDataLoading && (vehicleCompositionMode !== VehicleCompositionMode.SEARCH && vehicleCompositionMode !== VehicleCompositionMode.EDIT)) {
      if (data.vehicles?.[0]) {
        setVehicleCompositionMode(VehicleCompositionMode.VIEW)
      } else {
        setVehicleCompositionMode(VehicleCompositionMode.INIT)
      }
    }
  }, [data, isDataLoading, vehicleCompositionMode])

  return {
    isLoading: isAuthLoading || isLoading,
    data,
    upsertVehicleInfoMutation,
    vehicleCompositionMode,
    setVehicleCompositionMode
  }
})

export const VehicleInfoSection = () => {
  
  const {
    vehicleCompositionMode,
    setVehicleCompositionMode,
    upsertVehicleInfoMutation,
    data
  } = VehicleInfoSectionContainer.useContainer()


  switch (vehicleCompositionMode) {
    case VehicleCompositionMode.EDIT: {
      return <EditVehicleInformation />
    }
    case VehicleCompositionMode.VIEW: {
      return <VehicleInfoView />
    }
    case VehicleCompositionMode.LOADING: {
      return <Spinner />
    }
    case VehicleCompositionMode.INIT: {
      return <ActionCard
        icon={faCar}
        stepText="Step 2"
        title="Vehicle"
        details="Complete your Digital Drivers Card by saving your vehicle's information for quick access."
        buttonText="Add Your Vehicle"
        onClick={() => {
          setVehicleCompositionMode(VehicleCompositionMode.SEARCH)
        }}
      />
    }
    case VehicleCompositionMode.SEARCH: {
      return (
        <SearchVehicleInfo
          onSubmit={async values => {
            const updateValue = {
              ...values.specifications,
              madeIn: values.made_in,
              plate: values.plate,
              state: values.state,
            }
            upsertVehicleInfoMutation.mutate(updateValue)
          }}
          onCancel={() => {
            setVehicleCompositionMode(VehicleCompositionMode.VIEW)
          }}
          defaultValues={{
            plate: data?.vehicles?.[0]?.plate,
            state: data?.vehicles?.[0]?.state,
          }}
        />
      )
    }
  }
}