import config from "../../config"

interface MutationDeps {
  getAccessTokenSilently: () => Promise<string>
}

type MutationUpsertDriversLicenseOpts = {
  firstName: string
  lastName: string
  driversLicense: {
    imageKey: string
  }
}

export const mutationUpsertDriversLicense = (deps: MutationDeps) => async function (opts: MutationUpsertDriversLicenseOpts) {
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/upsert-drivers-license`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify(opts)
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}