import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, Text } from '../../foundations/Text'
import React from 'react'
import { Card } from "../../foundations/Card"
import { useHistory } from 'react-router-dom'
import { addPlea } from '../../feature/pleaSlice'
import { useDispatch } from 'react-redux'

export const ChoosePleaPage: React.FC = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const handleGuiltyClick = async () => {
    dispatch(addPlea("Guilty"))
    history.push("/plea-guilty")
  }
  const handleNoContestClick = async () => {
    dispatch(addPlea("No Contest"))
    history.push("/no-contest-choice")
    // history.push("/plea-no-contest")
  }

  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <Heading textAlign="center">
          Choose Plea
        </Heading>
        <Text>
          There are three plea options you can be present to the court for this citation:
        </Text>
        <ul style={{padding: "revert", fontSize: "16px"}}>
          <li>Guilty</li>
          <li>Not Guilty</li>
          <li>No Contest</li>
        </ul>
        <Text>
        <span style={{color: "red"}}>
          Failure to submit a plea to the court on or before the date shown on your
          ticket will result in a seperate offense being charged against you
          and a warrant being issued for your arrest.
        </span>
      </Text>
        <Card>
          <PrimaryButton onClick={handleNoContestClick} compact>No Contest (Nolo Contendere)</PrimaryButton>
          <Text>
            A plea of NO CONTEST means that you do not contest the charges against you.
            A plea of no contest cannot be used against you in the event of a subsequent
            civil suit for damages. 
            <br></br><br></br>
            If eligible, complete a Driver Safety Course or Deferred Disposition (probation) 
            to keep this violation off of your driver&apos;s record without trial. 
          </Text>
        </Card>
        <Card>
          <PrimaryButton onClick={handleGuiltyClick} compact>Guilty Plea</PrimaryButton>
          <Text>
            A plea of GUILTY means you admit fault for the violation and that you 
            understand the violation charged is prohibited by law. 
            This violation will appear on your driver&apos;s record.
          </Text>
        </Card>
        <Card>
          <PrimaryButton compact disabled>Schedule Court Date</PrimaryButton>
          <Text>
            Coming soon!
          </Text>
        </Card>
      </FlexColumn>
    </PageLayout>
  )
}
