import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import { WelcomeToCheckPage } from "./pages/Welcome.page"
import { NewUserPage } from "./pages/registration/NewUser.page"
import { CreateYourDigitalLicensePage } from "./pages/registration/CreateYourDigitalLicense.page"
import { AddAVehiclePage } from "./pages/registration/AddAVehicle.page"
import { ShareSocialPage } from "./pages/calltoactions/ShareTheMovement.page"

import { ConfirmVehicleDetailsPage } from "./pages/registration/ConfirmVehicleDetails.page"
import { ChoosePleaPage } from "./pages/citation/ChoosePlea.page"
import { TrafficTicketAwknowledgementPage } from "./pages/citation/TrafficTicketAwknowledgement.page"
import { NotGuiltyPage} from "./pages/citation/NotGuilty.page"
import { UploadCitationPage } from "./pages/citation/UploadCitation.page"
import { GuiltyPleaPage } from "./pages/citation/GuiltyPlea.page"
import { NoContestPleaPage } from "./pages/citation/NoContestPlea.page"
import { NoContestChoicePleaPage } from "./pages/citation/NoContestChoicePlea.page"
import { NoContestNoticePleaPage } from "./pages/citation/NoContestNoticePlea.page"
import { DefferedDispositionRequestPage } from "./pages/citation/DefferedDispositionRequest.page"
import { DrivingSafetyCourseRequestPage } from "./pages/citation/DrivingSafetyCourseRequest.page"
import { BillPage } from "./pages/citation/Bill.page"

import { getOperatingSystem, getBrowser} from './utils/platform'
import errorImage from "./assets/error.svg"

import { PageLayout } from "./components/Layout"
import { ResponsiveImageWrapper } from "./components/ResponsiveImageWrapper"
import { PrimaryHeading } from "./components/PrimaryHeading"

import { DashboardPage } from "./pages/DashboardPage/Dashboard.page"
import { EmergencyContactsPage } from "./pages/EmergencyContacts.page"
import { ProfilePage } from "./pages/ProfilePage/Profile.page"
import { ContactCallPage } from "./pages/ContactCallPage/ContactCallPage.page"
import { TrafficStopPage } from "./pages/DriverCallPage/TrafficStop.page"
import { DriverCallPage } from "./pages/DriverCallPage/DrivercallPage.page"
import { Card, Alert, Box, Text, Button } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { SafariNoticeContainer, SafariNoticeState } from "./context/SafariNotice.container"

export const SlugContext = React.createContext("")

export const App: React.FC = () => {

  const [show, setShow] = useState(true)
  const { safariNotice, setSafariNotice } = SafariNoticeContainer.useContainer()
  
  useEffect(() => {
    console.log(getOperatingSystem())
    console.log(getBrowser())
    if (getOperatingSystem() === 'iOS' && getBrowser() !== 'Safari') {
      setShow(false)
    }
  }, [])

  const _faExclamationTriangle = faExclamationTriangle as IconProp
  const _faTimes = faTimes as IconProp

  return (
    <Router>
      <PageLayout>
        <>
          {
            (getOperatingSystem() === 'iOS') && (safariNotice === SafariNoticeState.ENABLED) && (
              <Alert mb={4} px={5} py={4} bg="#f6e58d" color="black" sx={{
                fontWeight: "300",
                display: "flex",
                position: "relative"
              }}>
                <Box pr={6}>
                  <FontAwesomeIcon icon={_faExclamationTriangle}/>
                </Box>
                <Text>
                  Notice: If you&apos;re on iOS, the features of this app only works on Safari.
                </Text>
                <Button
                  variant="ghost"
                  onClick={() => setSafariNotice(SafariNoticeState.DISABLED)}
                  color="black"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    p: 3
                  }}
                >
                  <FontAwesomeIcon icon={_faTimes} />
                </Button>
              </Alert>
            )
          }
          <Switch>
            
            <Route path="/upload-citation" component={UploadCitationPage} />
            <Route path="/choose-plea" component={ChoosePleaPage} />
            <Route path="/not-guilty" component={NotGuiltyPage}/>
            <Route path="/plea-guilty" component={GuiltyPleaPage} />
            <Route path="/plea-no-contest" component={NoContestPleaPage} />
            <Route path="/no-contest-notice" component={NoContestNoticePleaPage} />
            <Route path="/no-contest-choice" component={NoContestChoicePleaPage} />
            <Route path="/deffered-disposition-request" component={DefferedDispositionRequestPage} />
            <Route path="/driving-safety-course-request" component={DrivingSafetyCourseRequestPage} />
            <Route path="/bill" component={BillPage} />
            <Route path="/traffic-ticket-awknowledgement" component={TrafficTicketAwknowledgementPage}/>
            <Route path="/confirm-vehicle-details" component={ConfirmVehicleDetailsPage} />
            <Route path="/registration" component={NewUserPage} />
            <Route path="/create-your-digital-license" component={CreateYourDigitalLicensePage} />
            <Route path="/add-a-vehicle" component={AddAVehiclePage} />
            <Route path="/dashboard" component={DashboardPage} />
            <Route path="/thank-you" component={ShareSocialPage} />
            <Route path="/emergency-contacts" component={EmergencyContactsPage} />
            <Route path="/digital-drivers-card" component={ProfilePage} />
            {/* <Route path="/confirm-your-details" component={ConfirmYourDetailsPage} /> */}
            <Route path="/watch-traffic-stop" component={ContactCallPage} />
            <Route path="/traffic-stop" component={TrafficStopPage} />
            <Route path="/" component={WelcomeToCheckPage} />
          </Switch>
          
        </>
      </PageLayout>
    </Router>
  )
}

// const Root = styled.div`
//   height: 100vh;
//   width: 100%;
//   display: grid;
//   grid-template-rows: 72px auto;

//   * {
//     /* width */
//     ::-webkit-scrollbar {
//       width: 8px;
//       padding-right: 4px;
//     }

//     /* Track */
//     ::-webkit-scrollbar-track {
//       background: none;
//       /* margin-left: 8px; */
//     }

//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//       background: #888;
//       transition: background 200ms;
//       border-radius: 4px;
//       width: 8px;
//     }

//     /* Handle on hover */
//     ::-webkit-scrollbar-thumb:hover {
//       background: #555;
//     }
//   }
// `
