
import { Checkbox } from "../../foundations/Checkbox"
import { Combobox } from '../../foundations/Combobox'
import { Box, FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { SecondaryButton, PrimaryButton } from '../../foundations/Buttons'
import { Select } from '../../foundations/Select'
import { Heading, Text } from '../../foundations/Text'
import { TextInput } from '../../foundations/Input'
import { useInput } from '../../utils/useInput'
import React from 'react'
import styled from '@emotion/styled'
import { Card } from "../../foundations/Card"
import { selectSpace } from "../../foundations/tokens"

export const TrafficTicketAwknowledgementPage: React.FC = () => {

  const readAndAcknowledged = useInput<boolean>(false)
  
  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <Heading textAlign="center">
          Traffic Ticket Awknowledgement
        </Heading>    
        <Card>
          <CardSection>
            <Heading textAlign="center">
              Driving Safety Course
            </Heading>
            <Text>
              You may be able to require that this charge be dismissed by
              successfully completing a driving safety course or a motorcycle
              operator training course. You will lose that right if, on or before
              your appearance date, you do not provide the court with notice of 
              you requiest to take the course.
            </Text>
          </CardSection>
          <CardSection>
            <Heading textAlign="center">
              Deferred Disposition (Probation)
            </Heading>
            <Text>
              You may request Deferred Disposition, but you must submit your
              request before the appearance date on your ticket.
            </Text>
          </CardSection>
          <CardSection>
            <Heading textAlign="center">
              Warning
            </Heading>
            <Text>
              A second or subsequent conviction of an
              offense under the Texas Motor Vehicle Safety
              Responsibility Act will result in the suspension
              of your driverʼs license and motor vehicle
              registration unless you file and maintain
              evidence of financial responsibility with the
              Department of Public Safety for two years from
              the date of conviction. The department may
              waive the requirement to file evidence of
              financial responsibility if you file satisfactory
              evidence with the department showing that at
              the time this citation was issued, the vehicle was
              covered by a motor vehicle liability insurance
              policy or that you were otherwise exempt from
              the requirements to provide evidence off
              financial responsibility.
            </Text>
          </CardSection>
          <CardSection>
            <Text>
              If you care convicted of a misdemeanor offense
              involving violence where you are or were a
              spouse, intimate partner, parent or guardian of
              the victim or are or were involved in another,
              similar relationship with the victim, it may be
              unlawful for you to possess or purchase a
              firearm, including a handgun or long gun, or
              ammunition, pursuant to federal law under 18
              U.S.C Section 922(g) (9) or Section 46.04 (b).
              Texas Penal Code. If you have any questions
              whether these laws make it illegal for you to
              possess or purchase a firearm, you should
            </Text>
          </CardSection>
        </Card>
        <Checkbox
          label="I have read and acknowledge the information above."
          checked={readAndAcknowledged.value}
          onChange={e => readAndAcknowledged.setValue(e.target.checked)}
        />
        <PrimaryButton disabled={!readAndAcknowledged.value}>Next</PrimaryButton>
      </FlexColumn>
    </PageLayout>
  )
}

const CardSection = styled(FlexColumn)`
  border-bottom: #ddd thin solid;
  /* padding-top: ${selectSpace(6)}; */
  padding-bottom: ${selectSpace(6)};
  gap: ${selectSpace(4)};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`