import React, { useState } from 'react'
import { Box, Button, Grid, Heading, Image, Spinner, Text } from 'theme-ui'
import Camera from 'react-html5-camera-photo'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from 'react-query'
import { addDriversLicenseToStoreAndDB } from '../../../../../api/mutations/addDriversLicenseV2.mutation'
import { PrimaryButton } from '../../../../../foundations/Buttons'
import { addCurrentData } from '../../../../../feature/driverLicenseModeSlice'
import { RootState } from '../../../../../store'
import { stat } from 'fs'
// const Camera = require('react-dom-camera')
// import { toast } from 'react-toastify'


export const UploadDriversLicense = () => {
	
	// Manage image state
	const [imageUrl, setImageUrl] = useState<string | null>(null)
	const [imageBlob, setImageBlob] = useState<Blob | null>(null)
	// const { isLoading: isAuthLoading, getAccessTokenSilently } = useAuth0()
	
	const storeImageInDatabaseMutation = useMutation(addDriversLicenseToStoreAndDB, {
		onSuccess: (response) => {
			console.log(response)
		}
	})
	
	const handleOnTake = async (dataUri: any) => {
		const blob = await (await fetch(dataUri)).blob()
		setImageBlob(blob)
		setImageUrl(dataUri)
		// console.log('[RODERICK] ===> handleOnTake', dataUri)
		// const payload = {
		// 	firstName: '',
    //  lastName: '',
    //   driversLicense: {
    //     imageKey: "",
    //   }
		// }
	}

	const handleConfirmation = (blob: Blob, image: any) => {
		console.log('CONSOLE.LOG IMAGEBLOB')
		storeImageInDatabaseMutation.mutate(image)
	}

	const ImageButton = ({ onClick }: any) => (
    <PrimaryButton onClick={onClick} compact>Upload Drivers License</PrimaryButton>
  )
	return (
		<Grid>
			<Heading variant='secondary'>
				Upload Your License
			</Heading>
			{
				!imageUrl ? (
					<Box sx={{
						borderRadius: "8px",
						overflow: "hidden", 
						background: "black",
					}}>
						<Camera onTakePhoto={(dataUri:any) => handleOnTake(dataUri) }/>
					</Box>

				):(
					<Grid>
						<Image src={imageUrl}/>
							<Button onClick={() => {
								if (imageBlob){
									handleConfirmation(imageBlob, imageUrl)
								}
							}} disabled={!imageBlob}>
								Confirm
							</Button>
					</Grid>
				)
			}
		</Grid>
	)
}