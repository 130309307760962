import { postRequest } from ".."
import { BaseResponse } from "@check/app-model"

export interface PaymentOpts {
    citationID: any,
}

export const makePayment = async ({
    citationID
}: PaymentOpts) => {
  const response = await postRequest<BaseResponse<any>>({
    endpoint: "/citations/" + citationID,
    body: {
      id: "makepayment",
      attributes: {},
    },
  })
  return response
}
