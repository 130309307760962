import React, { useState } from 'react'
import { useSelect, UseSelectStateChange } from 'downshift'
import { StyledInput } from './Input'
import styled from "@emotion/styled"
import { DesignTokens, selectFontSize, selectSpace } from './tokens'
import { _BaseButton } from "./Buttons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { css } from '@emotion/react'

interface SelectProps {
  items: string[],
  label: string,
  selectedItem: string,
  onSelectedItemChange: (changes: UseSelectStateChange<string>) => void
}

const _faCaretDown = faCaretDown as IconProp

export const Select: React.FC<SelectProps> = ({ items, label, selectedItem, onSelectedItemChange }) => {
  
    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect({ items, selectedItem, onSelectedItemChange })

    return (
      <div>
        <_Select>
          <_SelectButton
            type="button"
            {...getToggleButtonProps()}
            aria-label="toggle menu"
          >
            <_SelectText {...getLabelProps()}>
              {selectedItem || label}
            </_SelectText>
            <FontAwesomeIcon icon={_faCaretDown}/>
          </_SelectButton>
        </_Select>
        <_Menubox {...getMenuProps()}>
          {isOpen && (
            <_MenuboxItems>
              {
                items.map((item, index) => (
                  <_MenuboxItem
                    isActive={highlightedIndex === index}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item}
                  </_MenuboxItem>
                  ))
              }
            </_MenuboxItems>
          )}
        </_Menubox>
      </div>
    )
}

const _Menubox = styled.div`
  position: relative;
`
// const _MenuboxItems = styled.ul`
//   margin-top: 12px;
//   display: flex;
//   flex-direction: column;
//   border-radius: 4px;
//   box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
//   max-height: 256px;
//   overflow: auto;
//   padding: 12px;
//   position: absolute;
//   width: 100%;
//   background: white;
//   z-index: 500;
// `

const _MenuboxItems = styled.ul`
  margin-top: ${selectSpace(3)};
  display: flex;
  flex-direction: column;
  border-radius: ${selectSpace(1)};
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
  max-height: ${selectSpace(64)};
  overflow: auto;
  padding: ${selectSpace(3)};
  position: absolute;
  width: 100%;
  background: white;
  z-index: 500;
`

const _MenuboxItem = styled.li<{ theme?: DesignTokens, isActive: boolean }>`
  padding: ${selectSpace(3)};
  border-radius: ${selectSpace(1)};
  list-style: none;
  border: #fff thin solid;
  ${props => props.isActive ? css`
    border-color: #ddd;
    background: #f4f4f4;
  ` : css``}
`

// const _Select = styled.label`
//   border-radius: 16px; 
//   overflow: hidden;
//   border: #c4c4c4 2px solid;
//   position: relative;
//   display: flex;
// `

const _Select = styled.label`
  border-radius: ${selectSpace(2)}; 
  overflow: hidden;
  border: #c4c4c4 2px solid;
  position: relative;
  display: flex;
`

const _SelectButton = styled(_BaseButton)`
  font-size: ${selectFontSize(4)};
  padding: ${selectSpace(5)} ${selectSpace(6)};
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: none;
  border-radius: 0px;
  border: none;
  &:hover, &:focus {
    background: #f4f4f4;
  }
`
// const _SelectText = styled.span`
//   font-size: 16px
// `

const _SelectText = styled.span`
  font-size: ${selectFontSize(2)};
`