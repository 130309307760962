import React, { useEffect, useRef } from "react"
import config from "../../../config"
import styled from '@emotion/styled'
import IDImage from "../../../assets/id.svg"
import { Card } from "../../../foundations/Card"
import { Grid, Heading, Text, Input } from "@theme-ui/components"

const Camera = require('react-dom-camera')

const mountBlinkIdScriptTag = () => {

  const id = "blink-id-script-tag"
  const foundMountedBlinkIdScriptTag = document.getElementById(id)

  if (foundMountedBlinkIdScriptTag) {
    const script = document.createElement("script")
    script.id = id
    script.innerHTML = `
      const blink = document.querySelector("blinkid-in-browser")

      blink.includeSuccessFrame = true
      blink.wasmType = "BASIC"
  
      // See src/utils/translations.ts for possible translation keys
      blink.translations = {
        "action-message": "Securely Scan your Drivers License",
      }
    `
    script.async = true
    document.body.appendChild(script)
  }
}

export interface IDUploadProps {
  compact?: boolean,
  onReady?: (event: any) => void
  onFatalError?: (event: any) => void
  onScanSuccess: (results: ScanSuccessResults) => void
  onScanError?: (event: any) => void
  onFeedback?: (event: any) => void  
}

export interface ScanSuccessResults {
  firstName: string,
  lastName: string,
  dateOfBirth: {
    year: number,
    month: number,
    day: number
  },
  address: string,
  sex: string,
  vehicleClass: string,
  documentNumber: string,
  expiryDate: {
    day: number,
    empty: boolean
    month: number
    originalString: string
    successfullyParsed: boolean
    year: number
  },
  isDriversLicense: boolean,
  imageUri: string
}

/**
 * 
 * WASM Configuration: https://github.com/BlinkID/blinkid-in-browser#deployment-of-wasm-files
 */
// engine location version needs to match the @microblink/blinkid-in-browser-sdk version in package.json
const engineLocation = "https://unpkg.com/@microblink/blinkid-in-browser-sdk@5.11.1/resources"
// const engineLocation = `/resources`

export const IDUpload = ({
  onScanSuccess,
  onFatalError,
  onFeedback,
  onReady,
  onScanError,
  compact = false
}: IDUploadProps) => {

  const blinkId = useRef<any>(null)
  const licenseKey =  config.blinkIdKey

  useEffect(() => {
    mountBlinkIdScriptTag()
    const handleFatalError = (event: any) => {
      onFatalError?.(event)
      console.error("fatalError", event)
    }
    const handleReady = (event: any) => {
      onReady?.(event)
      console.log("ready", event)
    }
    const handleScanError = (event: any) => {
      onScanError?.(event)
      console.error("scanError", event)
    }
    const handleScanSuccess = async (event: any) => {

      const results = event.detail.recognizer

      const firstName = results.firstName || results.mrz.secondaryID
      const lastName = results.lastName || results.mrz.primaryID
      const dateOfBirth = {
        year: results.dateOfBirth.year || results.mrz.dateOfBirth.year,
        month: results.dateOfBirth.month || results.mrz.dateOfBirth.month,
        day: results.dateOfBirth.day || results.mrz.dateOfBirth.day,
      }
      const address = results.address || results.mrz.address
      const sex = results.sex || results.mrz.address
      const vehicleClass = results.driverLicenseDetailedInfo.vehicleClass
      const documentNumber = results.documentNumber || results.mrz.documentNumber
      const expiryDate = results.dateOfExpiry || results.mrz.dateOfExpiry
      const isDriversLicense =
        results.classInfo.documentType === 2 || results.mrz.documentType === 6

      const canvas = document.createElement('canvas')
      canvas.height = results.fullDocumentImage.rawImage.height
      canvas.width = results.fullDocumentImage.rawImage.width
      canvas.style.position = "absolute"
      canvas.style.left = 2 * results.fullDocumentImage.rawImage.width + "px"
      canvas.style.top = "0px"
      const context = canvas?.getContext?.("2d")
      context?.putImageData?.(results.fullDocumentImage.rawImage, 0, 0)
      const imageUri = canvas.toDataURL("image/jpeg")

      canvas.parentNode?.removeChild(canvas)

      onScanSuccess({
        firstName,
        lastName,
        dateOfBirth,
        address,
        sex,
        vehicleClass,
        documentNumber,
        expiryDate,
        isDriversLicense,
        imageUri
      })
    }
    const handleFeedback = (event: any) => {
      onFeedback?.(event)
      console.log("feedback", event)
    }
    blinkId?.current?.addEventListener("fatalError", handleFatalError)
    blinkId?.current?.addEventListener("ready", handleReady)
    blinkId?.current?.addEventListener("scanError", handleScanError)
    blinkId?.current?.addEventListener("scanSuccess", handleScanSuccess)
    blinkId?.current?.addEventListener("feedback", handleFeedback)
    let el: any = blinkId?.current
    el.wasmType = "BASIC"
    el.recognizerOptions = {
      "BlinkIdRecognizer": {
        "returnFullDocumentImage": true
      }
    }

    return () => {
      blinkId?.current?.removeEventListener("fatalError", handleFatalError)
      blinkId?.current?.removeEventListener("ready", handleReady)
      blinkId?.current?.removeEventListener("scanError", handleScanError)
      blinkId?.current?.removeEventListener("scanSuccess", handleScanSuccess)
      blinkId?.current?.removeEventListener("feedback", handleFeedback)
    }
  }, [blinkId, onScanSuccess])

  return compact ? (
    <Grid>
      {/* ref={blinkId}
      license-key={licenseKey}
      recognizers="BlinkIdRecognizer"
      engine-location={engineLocation}
      include-success-frame={true}
      recognizer-options="returnFullDocumentImage, returnFaceImage" */}
    </Grid>
  ) : (
    <Card>
      <Grid gap={6}>
        <Text sx={{ textAlign: "center" }}>
          Securely Scan your Photo ID
        </Text>
        <Camera>
          <IdImageContainer>
            <img src={IDImage} />
          </IdImageContainer>
        </Camera>
        {/* <blinkid-in-browser
          ref={blinkId}
          license-key={licenseKey}
          recognizers="BlinkIdRecognizer"
          engine-location={engineLocation}
          include-success-frame={true}
          recognizer-options="returnFullDocumentImage, returnFaceImage"
        ></blinkid-in-browser> */}
      </Grid>
    </Card>
  )
}

export default IDUpload

const IdImageContainer = styled.div`
  width: 100%;
  max-height: 320px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`