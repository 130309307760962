import { postRequest } from ".."
import { BaseResponse } from "@check/app-model"

export interface UpdateUserVehicleOpts {
  plate: string
  state: string
  make: string
  model: string
  vin: string
  year: string
  name: string,
  userState: Record<string, any>
}

export const updateUserVehicle = async ({
  plate,
  state,
  make,
  model,
  name,
  vin,
  year,
  userState
}: UpdateUserVehicleOpts) => {
  const response = await postRequest<BaseResponse<any>>({
    endpoint: "/users/vehicle",
    body: {
      id: "createvehicle",
      attributes: {
        id: userState.id,
        nickname: name,
        plate: plate,
        state: state,
        make: make,
        model: model,
        vin: vin,
        year: year,
      },
    },
  })
  return response
}
