import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, Text } from '../../foundations/Text'
import React from 'react'
import { Card } from "../../foundations/Card"
import { useHistory } from 'react-router-dom'
import { addDescription } from '../../feature/noContestDescriptionSlice'
import { useDispatch } from 'react-redux'

export const NoContestChoicePleaPage: React.FC = () => {

  const dispatch = useDispatch()

  const history = useHistory()
  const handleGuiltyClick = async () => {
    dispatch(addDescription("Deffered Disposition"))
    history.push("/deffered-disposition-request")
  }
  const handleNoContestClick = async () => {
    dispatch(addDescription("Driving Safety Course"))
    history.push("/driving-safety-course-request")
  }

  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <Text>
            Select an option to handle your violation
        </Text>
        <Card>
          <PrimaryButton onClick={handleNoContestClick} compact>Driving Safety Course</PrimaryButton>
          <Text>
            The charge for this violation may be dismissed by successfully completing a driving safety course
            or a motorcycle operator training course. 
            <br></br><br></br>
            You will lose that right if you do not appear before court on or before your appearance date with
            your request to take the driving safety course or motorcycle operator training course. 
          </Text>
        </Card>
        <Card>
          <PrimaryButton onClick={handleGuiltyClick} compact>Deferred Disposition</PrimaryButton>
          <Text>
            You may request Deferred Disposition,
            but you must submit your request before the appearance date on your ticket
          </Text>
        </Card>
      </FlexColumn>
    </PageLayout>
  )
}
