import React from 'react'

import addAVehicleImage from "../../assets/step-illustrations/add-a-vehicle.png"
import styled from '@emotion/styled'
import { PageLayout } from '../../components/Layout'
import { PrimaryHeading } from '../../components/PrimaryHeading'
import { ResponsiveImageWrapper } from '../../components/ResponsiveImageWrapper'
import { Button } from '../../components/Button'
import { useInput } from '../../utils/useInput'
import { useHistory } from 'react-router-dom'
import { ProgressDots } from '../../components/ProgressDots'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from 'react-query'
import { querySelf } from '../../api/queries/querySelf.query'
import { Box, Flex, Grid, Spinner } from '@theme-ui/components'
import { TextInput } from '../../foundations/Input'
import { Select } from '../../foundations/Select'
import { mutationAddVehicle } from '../../api/mutations/addVehicle.mutation'
import { toast } from 'react-toastify'

export const AddAVehiclePage: React.FC<{}> = () => {

  const { user, getAccessTokenSilently, isLoading: authIsLoading } = useAuth0()
  const {
    isLoading,
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: !!user,
    onSuccess: (data) => {
      if (data?.vehicles?.[0]?.vin) {
        history.push("/dashboard")
      }
    }
  })

  const licensePlate = useInput<string>("")
  const history = useHistory()
  const selectedState = useInput<string | null>(null)

  const addVehicleMutation = useMutation(mutationAddVehicle({ getAccessTokenSilently }), {
    onSuccess: () => {
      history.push("/confirm-your-details")
    },
    onError: (error: any) => {
      toast.error(error.message)
    } 
  })

  

  const handleClick = async () => {

    const label = selectedState.value as string
    const stateValue = statesAbbr.find(usState => usState.label === label)?.value

    await addVehicleMutation.mutate({
      plate: licensePlate.value,
      state: stateValue as string
    })
  }


  return (
    <>
      {
        isLoading || authIsLoading ? (
          <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
            <Spinner strokeWidth={1} size={96} color="primary" />
          </Flex>
        ) : <>
        <Grid gap={4}>
          <PrimaryHeading>
            Add A Vehicle
          </PrimaryHeading>
          <ResponsiveImageWrapper>
            <img src={addAVehicleImage} alt="Add A Vehicle"/>
          </ResponsiveImageWrapper>
          <TextInput {...licensePlate.bind} label="License Plate" />
          <Select
            items={statesAbbr.map(value => value.label)}
            label="State"
            onSelectedItemChange={e => selectedState.setValue(e.selectedItem as string)}
            selectedItem={selectedState.value as string}
          />
          {
            isLoading ? (
              <p>Loading...</p>
            ) : (
              <Button
                disabled={!licensePlate.value || !selectedState.value }
                onClick={handleClick}
              >
                Save Vehicle
              </Button>
            )
          }
          <Box pt={3}>
            <ProgressDots total={3} activeIndex={1} />
          </Box>
          
        </Grid>
        </>
      }
    </>
  )
}

const Label = styled.label`
  display: block;
  > span {
    font-size: 12px;
    letter-spacing: 0.05rem;
    margin-left: 2px;
    margin-bottom: 4px;
    display: block;
  }
`

const InputGroup = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const statesAbbr = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
]