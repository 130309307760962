import { Box, Flex, Grid, Spinner } from '@theme-ui/components'
import { faHeart, faIdBadge, faIdCard, faFile} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'
import { PrimaryHeading } from '../../components/PrimaryHeading'
import { querySelf } from '../../api/queries/querySelf.query'
import { SecondaryButton } from '../../foundations/Buttons'
import { Text } from '../../foundations/Text'
import { toast } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from 'react-query'
import React, { useEffect, useRef } from 'react'
import ResolveCitation from '../../components/DashboardCards/ResolveCitation'
// import { ChangeOrgCard } from './components/ChangeOrgCard'
import { DonationCard } from './components/DonationCard'
import { Variants, motion } from "framer-motion"
import { mutationNotifyEmergencyContacts } from '../../api/mutations/notifyEmergencyContacts.mutation'
import { useProtectedRoute } from '../../utils/useProtectedRoute'
import { ActionCard } from './components/ActionCard'
import { faCar, faRocket } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useReactPWAInstall } from "react-pwa-install";
import CheckLogoPng from "../../assets/checkLogo.png"
import Joyride, { Step, EVENTS } from 'react-joyride'
import { useState } from 'react'
import { RunTourContainer } from '../../context/RunTour.container'



const container: Variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item: Variants = {
  hidden: { opacity: 0, x: -200 },
  show: { opacity: 1, x: 0 }
}


export const DashboardPage: React.FC = () => {
  
  // const _faRocket = faRocket as IconProp
  
  const history = useHistory()

  const steps: Step[] = [
    {
      target: "#DashboardPageStep-1",
      content: "Add your friends and family to your emergency contacts so they can keep tabs on you!",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#DashboardPageStep-2",
      content: "Complete your Digital ID for quick access to your info during traffic stops.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#DashboardPageStep-3",
      content: "Be sure to sign our change.org petition!",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#DashboardPageStep-4",
      content: "And finally, share with your family and friends!",
      placement: "top",
      disableBeacon: true,
    },
  ]
  

  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading, error: authError, loginWithRedirect } = useAuth0()
  let {
    isLoading,
    data
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => toast.error(error?.message),
    onSuccess: (data: any) => {
      const hasBasicInfo = (data.firstName && data.lastName && data.email)
      if (!hasBasicInfo) {
        history.push("/registration")
      }
    }
  })

  useProtectedRoute()
  const {
    data: userData,
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated
  })
  
  let hasEmergencyContact = !!userData?.emergencyContacts?.length ? true : false
  let notifyEmergencyContactMutation = useMutation(mutationNotifyEmergencyContacts({ getAccessTokenSilently }), {
    onSuccess: (data) => {
      toast.success("Friends and Family notified! 🚀")
      history.push(`/traffic-stop?code=${data.accessToken}`)
    },
    onError: () => {
      toast.warn("Wasn't able to send, but try again and stay cool. 😎")
    }
  })

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const [runTour, setRunTour] = useState(false)
  const RunTour = RunTourContainer.useContainer()

  // console.log({ isInstalled: isInstalled(), supported: supported() })

  useEffect(() => {
    const isAllowed = !(isLoading || isAuthLoading) && RunTour.isAllowed 
    let timeout: any = undefined
    if (isAllowed) {
      timeout = setTimeout(() => {
        setRunTour(true)
      }, 1000)
    }
    return () => {
      if (!!timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isLoading, isAuthLoading, RunTour.isAllowed])

  return (
    <>
      {
        isLoading || isAuthLoading ? (
          <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
            <Spinner strokeWidth={1} size={96} color="primary" />
          </Flex>
        ) : (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Joyride
              steps={steps}
              debug={false}
              scrollOffset={72}
              continuous={true}
              run={runTour}
              callback={data => {
                RunTour.disable()
                if (data.type === EVENTS.TOUR_END) {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  })
                }
              }}
            />
            <Grid pb={4} sx={{ gap: 3 }}>
              <PrimaryHeading>
                Welcome to Check!
              </PrimaryHeading>
              <Text textAlign="center">
                The Traffic Stop Video Chat App  
              </Text>
            </Grid>
            <Grid  sx={{ gap: 8 }}>
              <motion.div variants={item}>
                <Box id="DashboardPageStep-1">
                  <ResolveCitation onNotifyClick={() => {
                    notifyEmergencyContactMutation.mutate({})
                  }} hasEmergencyContact={ hasEmergencyContact }/>
                </Box>
              </motion.div>
              <motion.div variants={item}>
                <Box id="DashboardPageStep-2">
                  <ActionCard
                    onClick={() => {
                      history.push("/digital-drivers-card")
                    }}
                    stepText="Next Steps"
                    title="Digital ID"
                    details="Complete your Digital ID for easier traffic stops."
                    buttonText="Get Started"
                    icon={faIdCard}
                  />
                </Box>
              </motion.div>
              {
                (!isInstalled() && supported() && false) && (
                  <motion.div variants={item}>
                    <Box id="">
                      <ActionCard
                        onClick={() => {
                          pwaInstall({
                            title: "Install Check",
                            logo: CheckLogoPng
                          })
                        }}
                        stepText="Step 2"
                        title="Add to Phone"
                        details="Be sure to add Check to your homescreen for quick access."
                        buttonText="Add to Homescreen"
                        icon={faRocket}
                      />
                    </Box>
                  </motion.div>
                )
              }
              <motion.div variants={item}>
                <Box id="DashboardPageStep-3">
                  <ActionCard
                    onClick={() => {
                      history.push("/upload-citation")
                    }}
                    stepText="Whenever you're ready"
                    title="Citation Resolution"
                    details="Use Check to manage your Citations."
                    buttonText="Get Started"
                    icon={faFile}
                  />
                </Box>
              </motion.div>
              <motion.div variants={item}>
                <Box id="DashboardPageStep-4">
                  <SecondaryButton as={(props) => <Link {...props} to={'/thank-you'}/>}>
                    <Flex sx={{ alignItems: "center" }}>
                      <FontAwesomeIcon icon={faHeart} />
                      <Box sx={{ paddingLeft: 3 }}>Share</Box>
                    </Flex>
                  </SecondaryButton>
                </Box>
              </motion.div>
            </Grid>
          </motion.div>
        )
      }
      {/* <HomePageActions /> */}
    </>
  )
}

