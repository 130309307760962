import axios from "axios"
import { querySignedAwsPutURL } from "../queries/querySignedAwsPutURL"
import { querySignedPutUrl } from "../queries/querySignedPutUrl.query"

export const addDriversLicenseToStoreAndDB = async function (blob: any) {

	// console.log('[RODERICK] ===> addDriversLicenseToStoreAndDB')

	// Query to retrive secure URL From AWS to post image to S3 bucket
	// const { url: signedUrl, key } = await querySignedAwsPutURL()
	// const testRealUrl = `http://d20e2b52s2txlr.cloudfront.net/test07?Expires=1648353579&Signature=cwQVnKlAOdbHtVpjBpJCwSS2HawEYfGRW9EOxIqgF2Mh~JhFsNSI5c3fmr7dpfk6gicjbhNaJgNuI~Tqhnu~ncOy-2mQGZtSVjHA5pRtv2ptlARXMFUdyPzYmxnWjUW937aHg9CNugQk7zil7vh9Vweq8Wnpk2-iCnsHq6fzYGYi8wXQji0GGbyV4D8H3pNrmLIrYNkZTvtVTgI6tlkkbicjuqM-OHaqICroIaniLAH-07f-bp9DizaOd0IQsVQ7tLoNrmwEhdRr5KKPJA-chYAp3bavh6dV5Ea9iUGTzCDEvlKxYNorK5qqF53vzFJgSksaj2Vyt5jB97xdtJYwmg__&Key-Pair-Id=KC3HXXWWUKZP2`

	const data = await querySignedPutUrl()
	console.log('[RODERICK] ===> TEST PUTURL', data)
	const {signedUrl: signedUrl, key} = await querySignedPutUrl()

	// Will use the secured URL from AWS and post the image
  const urlToRetriveImageFromAws = (blob: any) => {
		const imagez = 'https://m.media-amazon.com/images/I/71xpUOAu-PL._AC_SL1500_.jpg'
			axios({
			url: signedUrl,
			method: 'PUT',
			data: blob
		})
		.then((response) => {
			console.log('[RODERICK] ===> urlToRetriveImageFromAws',{signedUrl, blob})
			const data = response
			return data
		})
		.catch(function (error) {
			console.log('[RODERICK] ===> ERROR: urlToRetriveImageFromAws', error, imagez);
		});
	}

	// Sumbit image to database
	const submitImageAndUrlToDatabase = (blob: any) => {
		
		axios({
			url: `https://880d13hc1g.execute-api.us-west-1.amazonaws.com/dev/add/data?test=\'${blob}`,
			method: 'post',
		})
		.then(function (response) {
			console.log(JSON.stringify(response.data));
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	// return submitImageAndUrlToDatabase(blob)
	const putImageInAWS = urlToRetriveImageFromAws(blob)
	putImageInAWS
}