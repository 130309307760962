import { Button, Grid, Heading, Text } from "theme-ui"
import { VehicleCompositionMode, VehicleInfoSectionContainer } from "../VehicleInfoSection"

export const VehicleInfoView = () => {

  const { setVehicleCompositionMode, data } = VehicleInfoSectionContainer.useContainer()

  return (
    <Grid>
      <Heading variant="secondary">
        Vehicle Information
      </Heading>
      <Heading variant="tertiary">
        Actions
      </Heading>
      <Grid gap={2} columns="1fr 1fr" sx={{ alignContent: 'flex-start' }}>
        <Button
          sx={{ fontSize: 1, py: 2 }}
          onClick={() => setVehicleCompositionMode(VehicleCompositionMode.SEARCH)}
        >
          Quick Lookup
        </Button>
        <Button
          sx={{ fontSize: 1, py: 2 }}
          onClick={() => setVehicleCompositionMode(VehicleCompositionMode.EDIT)}
        >
            Make Corrections
        </Button>
      </Grid>
      <Heading variant="tertiary">
        Quick Info
        </Heading>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Plate
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.plate}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          State
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.state}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Make
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.make}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Model
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.model}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Vin
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.vin}
        </Text>
      </Grid>
      <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
        <Heading variant="data-label" as="h3">
          Year
        </Heading>
        <Text variant="data-value">
          {data?.vehicles?.[0]?.year}
        </Text>
      </Grid>
    </Grid>
  )
}