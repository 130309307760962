import { State } from "@check/app-model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface DriverLicense {
  value: {};
}

const initialState: DriverLicense = {
  value: {image: null}
};

export const driverLicenseSlice = createSlice({
  name: "driverLicense",
  initialState,
  reducers: {
		addDriversLicense: (state, action) => {
			state.value = action.payload
		},
		addCurrentData: (state, action) => {
			state.value =  action.payload
		},
		setCurrentData: (state, action) => {
			state.value =  action.payload
		},
		
  },
});

export const { addDriversLicense, addCurrentData,setCurrentData } = driverLicenseSlice.actions;

export default driverLicenseSlice.reducer;