import React, { useEffect, useState, VideoHTMLAttributes } from 'react'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { AspectRatio, Box, Button, Flex, Grid, Heading, Image, Spinner, Text } from '@theme-ui/components'
import queryString from 'query-string'
import { motion } from "framer-motion"

import { connect, RemoteTrack, RemoteParticipant, RemoteAudioTrack, RemoteVideoTrack, createLocalVideoTrack, createLocalAudioTrack } from "twilio-video"
import { useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { querySelf } from '../../api/queries/querySelf.query'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { VehicleInfo } from './components/VehicleInfo'
import { InsuranceInfo } from './components/InsuranceInfo'
import { queryActualToken } from '../../api/queries/queryActualToken.query'
import { Modal } from '../../foundations/Modal'
import { giveConsentMutation } from '../../api/mutations/giveConsent.mutations'


const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication?.track)
      .filter((track) => track !== null);


enum CallState {
  INIT = "INIT",
  LOADING = "LOADING",
  CONNECTED = "CONNECTED"
}

export const TrafficStopPage = () => {

  const { search } = useLocation()
  const parsed = queryString.parse(search)
  // const [code, setCode] = useState<string>()
  const [room, setRoom] = useState<any>()

  const [userConsent, setUserConsent] = useState(true)
  const [showModal, setShowModal] = useState(true)
  

  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading, loginWithRedirect } = useAuth0()
  let {
    isLoading,
    data,
    refetch
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => {
      toast.error(error?.message)
    }
  })

  const {
    isLoading: codeLoading,
    data: codeData,
  } = useQuery(['accessToken', parsed?.driverToken], queryActualToken({ shortAlias: parsed?.driverToken as string }))

  const [callState, setCallState] = useState<CallState>(CallState.INIT)

  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const [participant, setParticipant] = useState<RemoteParticipant>()

  const selfVideoRef = useRef<any>();
  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  useEffect(() => {
    if(!isAuthenticated && !isAuthLoading) {
      const redirectUri = `${window.location.origin}/traffic-stop?driverToken=${parsed?.driverToken}`
      loginWithRedirect({ redirectUri: window.location.href })
    }
    if(isAuthenticated && !isAuthLoading) {
      refetch()
    }
  },[isAuthenticated, isAuthLoading])

  useEffect(() => {
    ;(async function init() {
      const code = await queryActualToken({ shortAlias: parsed?.driverToken as string })
      
      if (!(isLoading || codeLoading || isAuthLoading)) {
        const localVideoTrack = await createLocalVideoTrack({
          aspectRatio: 16 / 9,
        })
        localVideoTrack.attach(selfVideoRef.current)
        const localAudioTrack = await createLocalAudioTrack({})
        const room = await connect(codeData?.actualToken, {
          tracks: [localVideoTrack, localAudioTrack],
        })
        setRoom(room)

        // make sure to render video track
        room.participants.forEach(participant => {
            console.log({ participant })
            setParticipant(participant)
        })

        // make sure to render video track
        room.on("participantConnected", participant => {
            console.log({ participant })
            setParticipant(participant)
        })
        room.on("participantDisconnected", value => console.log("participantDisconnected", value))
        room.on("participantReconnected", setParticipant)
        room.on("participantReconnecting", value => console.log("participantReconnecting", value))
        room.on("trackMessage", value => console.log("trackMessage", value))
      }
    })()
  }, [selfVideoRef, isLoading, isAuthLoading])

  useEffect(() => {
    console.log({ participant })
    if (participant) {
      setVideoTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.videoTracks)]);
      setAudioTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.audioTracks)]);
  
      const trackSubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };
  
      const trackUnsubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
      };
  
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
  
      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];

    setCallState((prevState) => {
      if (prevState === CallState.INIT) {
        return prevState
      }
      return videoTrack ? CallState.CONNECTED : CallState.LOADING
    })

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const giveConsent = async ( consent: boolean ) => {
    console.log('[RODERICK] ===> giveConsent')
    const consentMutation = await giveConsentMutation({
      consent: consent,
      driverInfo: { ...data },
      driverToken: parsed.driverToken as string ?? ''
    })

    console.log('[RODERICK] ===> CONSENTMUTATION',consentMutation)

    setUserConsent(consentMutation.data.consent)
    setShowModal(false)

    if (!consentMutation.data.consent) {
      setVideoTracks([]);
      setAudioTracks([]);
      participant?.removeAllListeners();
    }
  }

  const ConsentModal = () => (
    <Modal
      isModalOpen={showModal}
      onRequestClose={() => {}}
    >
      <Heading>Do you consent to this virtual traffic stop?</Heading>
      <Text sx={{ marginTop: '20px' }}>
        By consenting you are giving the officer permission to conduct the stop over video chat, 
        along with permission to view your license and user data.
      </Text>
      <Text sx={{ marginTop: '10px' }}>
        If you do not consent the traffic stop will be held in person.
      </Text>
      <Flex sx={{ justifyContent: 'space-between', marginTop: '20px' }}>
        <Button onClick={() => giveConsent(false)}>I do NOT consent</Button>
        <Button onClick={() => giveConsent(true)}>I consent</Button>
      </Flex>
    </Modal>
  )

  return isLoading || isAuthLoading ? (
    <>
      <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
        <Spinner strokeWidth={1} size={96} color="primary" />
      </Flex>
    </>
  ) : (
    <>
    <ConsentModal />
    
    { userConsent &&
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Grid gap={4}>
          {
            callState === CallState.INIT || callState === CallState.LOADING &&
              <>
                  <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
                      <Spinner strokeWidth={1} size={96} color="primary" />
                  </Flex>
              </>
          }
        </Grid>
        <Grid gap={4}>
          <>
              <Heading mr={2}>
                  Officer Cam
              </Heading>
              <AspectRatio ratio={16 / 9} sx={{
                  width: "100%",
                  bg: "#333",
                  borderRadius: "8px",
                  overflow: "hidden",
                  
                  "> video": {
                  height: "100%",
                  width: "100%",
                  objectFit: "contain"
                  }
              }}>
                  <video ref={videoRef} autoPlay={true}/>
              </AspectRatio>
            <audio ref={audioRef} autoPlay={true} />
          </>
          <Flex>
            <Heading mr={2}>
              Video Cam
            </Heading>
            <motion.div
              transition={{ 
                repeat: Infinity,
                duration: 2,
              }}
              animate={{
                opacity: [0, 1, 0],
              }}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box sx={{ background: "red", height: "18px", width: "18px", borderRadius: "50%" }}/>
            </motion.div>
          </Flex>
          <Text>
            You&apos;re now streaming to your emergency contacts.
          </Text>
          {/* <AspectRatio ratio={16 / 9} sx={{
              width: "100%",
              bg: "#333",
              borderRadius: "8px",
              overflow: "hidden",
              "> video": {
              height: "100%",
              width: "100%",
              objectFit: "contain"
              }
          }}>
              <video ref={selfVideoRef}/>
          </AspectRatio> */}
          <Grid gap={4}>
              <Heading>
              Drivers Info
              </Heading>
              <Heading variant="secondary">
              Drivers License
              </Heading>
              <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
              <Heading variant="data-label" as="h3">
                  Drivers License Number
              </Heading>
              <Text variant="data-value">
                  {data?.driversLicense?.number}
              </Text>
              </Grid>
              <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
              <Heading variant="data-label" as="h3">
                  First Name
              </Heading>
              <Text variant="data-value">
                  {data?.firstName}
              </Text>
              </Grid>
              <Grid gap={1} px={2} sx={{ borderLeft: "#ddd 4px solid" }}>
              <Heading variant="data-label" as="h3">
                  Last Name
              </Heading>
              <Text variant="data-value">
                  {data?.lastName}
              </Text>
              </Grid>
              <Box sx={{
              borderRadius: "8px",
              overflow: "hidden"
          }}>
              <Image
                  sx={{
                      display: "block"
                  }}
                  src={data?.driversLicense?.imageUrl}
              />
          </Box>
          </Grid >
          {data?.vehicles?.[0] && <VehicleInfo data={data}/>}
          {data?.insurance?.insuranceCardImageUrl && <InsuranceInfo data={data}/>}
          </Grid>
      </motion.div>
    }
    { !userConsent &&
      <Grid gap={4}>
        <Heading>
          Consent Not Given
        </Heading>
        <Text variant="data-value">
          You did not consent to the virtual traffic stop. The stop will proceed in person.
        </Text>
      </Grid>
    }
    </>
  )
}

function useInput<T>(foundCode: string) {
    throw new Error('Function not implemented.')
}
