import React from 'react'
import { Box, Button, Grid, Heading, Image } from '@theme-ui/components'
import { InsuranceSectionContainer, InsuranceSectionMode } from '../InsuranceSection'


export const InsuranceInfoView = () => {
  const { data, setMode } = InsuranceSectionContainer.useContainer()
  return (
    <Grid gap={4}>
      <Heading variant="secondary">
        Proof of Insurance
      </Heading>
      <Heading variant="tertiary">
        Actions
      </Heading>
      <Button onClick={() => setMode(InsuranceSectionMode.UPSERT)}>
        Update
      </Button>
      <Heading variant="tertiary">
        Insurace Card Image
      </Heading>
      <Box sx={{
        borderRadius: "8px",
        overflow: "hidden"
      }}>
        <Image
          sx={{
            display: "block"
          }}
          src={data.insurance.insuranceCardImageUrl}
        />
      </Box>
    </Grid>
  )
}