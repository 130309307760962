import TagManager from "react-gtm-module"

import config from "../../config"

const initTagManager = async (): Promise<void> => {
  try {
    TagManager.initialize({ gtmId: config.tagManager.analyticsId })
    if (!config.isProd) console.log("Tag Manager Initialized")
  } catch (e) {
    if (!config.isProd) console.log(e)
  }
}

export const initTracker = (): void => {
  Promise.all([initTagManager()])
}

// Initializes component with pageView tracker. Only needed on app root
// export const withTracker = <P extends RouteComponentProps>(
//   WrappedComponent: React.ComponentType<P>,
//   options: FieldsObject = {}
// ) => {
//   const trackPage = (page: string) => {
//     ReactGA.set({ page, ...options })
//     ReactGA.pageview(page)
//     Pixel.pageView()
//   };

//   return (props: P) => {
//     useEffect(() => {
//       trackPage(props.location.pathname)
//     }, [props.location.pathname])

//     return <WrappedComponent {...props}/>
//   }
// }

// Initializes history listener
// const initHistoryListener = async (history: History): Promise<void> => {
//   try {
//     history.listen(location => {
//       ReactGA.set({ page: location.pathname })
//       ReactGA.pageview(location.pathname)
//     })
//     if (!config.isProd) console.log("History Listener Initialized")
//   } catch (e) {
//     if (!config.isProd) console.log(e)
//   }
// }

// Initializes Route component with pageView tracker
// export const TrackedRoute: React.FC<RouteProps> = (props) => {
//   const location = useLocation();

//   const trackPage = useCallback((page: string) => {
//     console.log(page)
//     ReactGA.set({ page })
//     ReactGA.pageview(page)
//   }, []);

//   useEffect(() => {
//     if(location.pathname) {
//       trackPage(location.pathname)
//     }
//   }, [trackPage, location])
//   return (
//     <Route {...props}/>
//   )
// }
