import axios from "../index"
import config from "../../config"

export const querySignedPutUrl = async () => {
  const baseUrl = config.api.url
  console.log(`${baseUrl}api/v1/presigned/signed-put-url`)
  const response = await axios({
    method: "POST",
    // url: "/citations/"
    // url: "http://localhost:8080/api/v1/presigned/signed-put-url"
    url: `https://api.staging.getcheckapp.com/api/v1/presigned/signed-put-url`
  })
  console.log('[] ===========> querySignedPutUrl', response)
  return response.data.body
}
