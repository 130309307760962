import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, SecondaryHeading, Text } from '../../foundations/Text'
import React, { useState } from 'react'
import { DesignTokens } from "../../foundations/tokens"
import { useTheme } from "@emotion/react"
import PDFGenerator, { GeneratePDF } from "../../components/PDFGenerator"
import SignatureInput from "../../components/SignatureInput"
import { useHistory } from 'react-router-dom'
import { Checkbox } from '../../foundations/Checkbox'

export const DefferedDispositionRequestPage: React.FC = () => {

  const theme = useTheme() as DesignTokens
  const history = useHistory()
  const [didAcknowledge, setDidAcknowledge] = useState(false)


  const citationNumber = "3220523"
  const fullName = "Nicolas Fisher"

  const handleClick = () => {
    if(didAcknowledge) {
      GeneratePDF("innerContent")
      history.push("/bill")
    }
  }
  
  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <PDFGenerator genId={"innerContent"}> 
            <Heading textAlign="center">
              Deferred Disposition Request
              <br></br><br></br>
            </Heading>
            <Text>
              I wish to enter a plea of NO CONTEST, 
              waive my right to a jury trial, and request Deferred Disposition. 
              I decline to review the evidence, if any, in this case. 
              I understand that the Court will defer further proceedings without entering an adjudication 
              of guilt and place me on probation for a period of 180 days during which time 
              I must not receive any violations.  
              If I successfully complete the deferral period without receiving an additional violation 
              the case will be dismissed and will not appear on my record as a conviction.
            </Text>
            <Text>
              I understand that if I was under the age of 25 on the date of my citation, I am required to:
              <br></br><br></br>
              <ul style={{padding: "revert"}}>
                <li>Complete a Driver&apos;s Safety Course</li>
                <li>Request a certified copy of my Driving Record</li>
                <li>Submit Certificate of completed DSC &amp; DSR</li>
                <li>A copy of my valid driver&apos;s license</li>
              </ul>
              <br></br>
            </Text>
            <Text>
              The Defendant has request that they be granted Deferred Disposition 
              pursuant to Article 45.051 of the Texas Code of Criminal Procedure, 
              as amended, and that is support of the Defendantʼs application 
              would show the Court the following:
              <br></br><br></br>
              <ul style={{padding: "revert"}}>
                <li>That I have not participated in a Deferred Disposition Program 
                    during the past one year (c alculated from the date of previous dismissal 
                    to the date of your current citation).</li>
                <li>I am not currently participating in a Deferred Disposition Program in any other Court.</li>
                <li>That I have not received two or more final convictions for traffic offenses, 
                    class “C” misdemeanors or ordinance violations within the last year 
                    (calculated from the date of previous conviction to the date of your current citation).</li>
                <li>I do not have any cases currently set for and awaiting trial.</li>
                <li>I have a valid Driver&apos;s License</li>
              </ul>
              <br></br>
            </Text>
            <Checkbox label="The forgoing representations are true and current in all respects, 
                            and by signing below acknowledge the accuracy of the above statements." 
                      checked={didAcknowledge}
                      onChange={e => setDidAcknowledge(!didAcknowledge)}
            />
            <SecondaryHeading textAlign="center">
                SIGN
            </SecondaryHeading>
            <SignatureInput style={{ marginTop: '20px' }}/>
        </PDFGenerator>
        <PrimaryButton disabled={!didAcknowledge} onClick={handleClick}>Next</PrimaryButton>
      </FlexColumn>
    </PageLayout>
  )
}