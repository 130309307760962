import React, { useEffect } from 'react'
import { PageLayout } from '../components/Layout'
import { SecondaryHeading, Text } from '../foundations/Text'
import { Flex, Box } from "reflexbox"
import { GhostButton, PrimaryButton } from '../foundations/Buttons'
import styled from '@emotion/styled'
import { PrimaryHeading } from '../components/PrimaryHeading'
import { Modal } from '../foundations/Modal'
import { useState } from 'react'
import { useForm, useField } from 'react-form'
import { TextInput } from '../foundations/Input'
import { useAuth0 } from '@auth0/auth0-react'

import { AsYouType, isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { selectSpace } from '../foundations/tokens'
import { useMutation, useQuery } from 'react-query'
import { mutationAddEmergencyContact } from '../api/mutations/addEmergencyContact.mutation'
import { querySelf } from '../api/queries/querySelf.query'
import { Spinner } from '@theme-ui/components'
import { mutationRemoveEmergencyContact } from '../api/mutations/removeEmergencyContact.mutation'
import { toast } from 'react-toastify'
import { useProtectedRoute } from '../utils/useProtectedRoute'


const validateName = async ( value: any , instance: any) => {
    return instance.debounce( () => {
      if(value) {
        return false
      } else {
        return "Please enter a name."
      }
    }, 1000)

}

export const NameField = () => {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField("name", {
    validate: validateName
  });
  return (
    <Flex flexDirection="column">
      <TextInput {...getInputProps()} label="Name"/>
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  )
}

const validatePhone = async ( value: any , instance: any) => {
    return instance.debounce(async () => {
      if (value) {
        const isValid = isValidPhoneNumber(value, "US")
        if (isValid) {
          const formattedValue = new AsYouType("US").input(value)
          instance.setValue(formattedValue)
          return false
        } else {
          return "Not a valid phone number."
        }
      } else {
        return "Please enter a phone number."
      }
    }, 1000)
  
}

export const PhoneNumberField = () => {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("phone", {
    validate: validatePhone
  });
  return (
    <Flex flexDirection="column">
      <TextInput {...getInputProps()} label="Phone Number" type="tel"/>
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  )
}

const ErrorText = styled(Text)`
  color: red;
  padding-right: ${selectSpace(4)};
`


export const EmergencyContactsPage = () => {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0()
  useProtectedRoute()

  const {
    data: userData,
    error,
    isFetching,
    isLoading,
    refetch,
    dataUpdatedAt,
    remove
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated
  })

  const addEmergencyContactMutation = useMutation(async ({ name, phone }: any) => {
    const accessToken = await getAccessTokenSilently()
    return await mutationAddEmergencyContact({ accessToken, phone, name })
  }, {
    onMutate: async ({ name, phone }: any) => {
      let user = await refetch()
      let enteredNumber = parsePhoneNumberFromString(phone, 'US')?.number

      if(enteredNumber == user?.data?.phone){
        throw {message: 'Please enter a phone number that is not your own.'}
      }

      if (!!user?.data?.emergencyContacts?.length){
        let contacts = user?.data?.emergencyContacts
        if(contacts.some((entry: any) => entry.phone == phone)){
          throw {message: 'A contact with this phone number already exists.'}
        }
      }
    },
    onSuccess: async () => {
      let result = await refetch()
      console.log({ result })
    },
    onError: (error: any) => {
      toast.error(error.message)
    }
  })
  
  const removeEmergencyContactMutation = useMutation(mutationRemoveEmergencyContact({ getAccessTokenSilently }), {
    onSuccess: async () => {
      await refetch()
    }
  })

  

  const {
    Form,
    meta: {
      isSubmitting,
      canSubmit,
    },
    reset
  } = useForm({
    onSubmit: async (values, { reset,  }) => {
      await addEmergencyContactMutation.mutate(values)
      setIsModalOpen(false)
      reset()
    }
  })


  return (
    <>
      {
        isAuthLoading || isLoading ? (
          <>
            <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
              <Spinner strokeWidth={1} size={96} color="primary" />
            </Flex>
          </>
        ) : (
          <>
            <Modal isModalOpen={isModalOpen} onRequestClose={() => {
              setIsModalOpen(false)
              reset()
            }} >
              <Form>
                <Box display="grid" sx={{ gap: 3 }} py={3}>
                  <SecondaryHeading style={{ textAlign: "center" }}>
                    Add Emergency Contact
                  </SecondaryHeading>
                  <NameField />
                  <PhoneNumberField />
                  <PrimaryButton type="submit">
                    Save
                  </PrimaryButton>
                </Box>
              </Form>
            </Modal>
            <Box display="grid" sx={{ gap: 4 }}>
              <PrimaryHeading>
                Emergency Contacts
              </PrimaryHeading>
              <ContactsList>
                {
                  !!userData?.emergencyContacts?.length ? <>
                    { userData?.emergencyContacts?.map?.((contactInfo: any) => <EmergencyContactCard
                      key={contactInfo.phone + contactInfo.name}
                      {...contactInfo}
                      onRemove={ async () => {
                        await removeEmergencyContactMutation.mutate(contactInfo)
                      }}
                    />)}
                  
                  </> : <>
                    <Box p={4} justifyContent="center">
                      <SecondaryHeading>
                        No contacts saved yet?
                      </SecondaryHeading>
                      <Text>Add an emergency contact and drive safely!</Text>
                    </Box>
                  </>
                }
              </ContactsList>
              <PrimaryButton onClick={() => setIsModalOpen(true)}>
                { addEmergencyContactMutation.isLoading ? "Submitting..." : "Add Emergency Contact" }
              </PrimaryButton>
            </Box>
          </>
        )
      }
    </>
  )
}

const ContactsList = styled.div`
  display: flex;
  flex-direction: column;
  border: #ddd 2px solid;
  > * {
    border-bottom: #ddd 2px dashed;
    &:last-child {
      border-bottom: none;
    }
  }
`

interface EmergencyContactCard {
  name: string,
  phone: string,
  onRemove: () => void,
  isRemoving?: boolean,
}

export const EmergencyContactCard: React.FC<EmergencyContactCard> = ({
  name,
  phone,
  onRemove,
  isRemoving = false
}) => {

  return (
    <Flex justifyContent="space-between" alignItems="center" p={3}>
      <Box>
        <Text>{name}</Text>
        <Text>{phone}</Text>
      </Box>
      <Box>
        <GhostButton compact onClick={onRemove}>
          Remove
        </GhostButton>
      </Box>
    </Flex>
  )
}
