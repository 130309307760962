import React from 'react'

import welcomeImage from "../assets/step-illustrations/welcome-to-check.png"
import secureData from "../assets/step-illustrations/secure_data.png"
import notify from "../assets/step-illustrations/notify.png"
import licensePlate from "../assets/step-illustrations/licenseplate.png"
import driversLicense from "../assets/step-illustrations/dllicense.png"
import insurance from "../assets/step-illustrations/insurance.png"
import { PageLayout } from '../components/Layout'
import { PrimaryHeading } from '../components/PrimaryHeading'
import { SecondaryHeading } from '../components/SecondaryHeading'
import { useAuth0 } from '@auth0/auth0-react'
import { PrimaryButton } from '../foundations/Buttons'
import { useHistory } from 'react-router-dom'
import { useQuery } from "react-query"
import { querySelf } from '../api/queries/querySelf.query'
import { Box, Button, Flex, Spinner } from '@theme-ui/components'
import { useThemeUI } from 'theme-ui'

import styled from "@emotion/styled"
import Carousel, { RenderPaginationProps } from "react-elastic-carousel"

export const WelcomeToCheckPage: React.FC<{}> = () => {

  const history = useHistory()

  const { loginWithRedirect, user, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0()
  const {
    isLoading,
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: !!user,
    retry: 1,
    onSuccess: () => {
      history.push('/dashboard')
    },
  })

  const WelcomeComponent = () => (
    <Component>
      <PrimaryHeading>
        Welcome to Check
      </PrimaryHeading>
      <SecondaryHeading>
        Your personal traffic stop assistant
      </SecondaryHeading>
      <ImageWrapper >
        <img src={welcomeImage} alt="Welcome to Check!"/>
      </ImageWrapper>
    </Component>
  )

  const InformationSafteyComponent = () => (
    <Component>
      <PrimaryHeading>
        Data Security Focused
      </PrimaryHeading>
      <ImageWrapper >
        <img src={secureData} alt="Data Security"/>
      </ImageWrapper>
      <p>
        We use cutting edge authentication and data encryption technologies to keep your information safe.
        <br/><br/>
        Want to learn more, Check out our <a href='https://getcheckapp.com/' target='blank'>website</a>.
      </p>
    </Component>
  )

  const NotifyComponent = () => (
    <Component>
      <PrimaryHeading>
        Alert Friends and Family
      </PrimaryHeading>
      <ImageWrapper >
        <img src={notify} alt="Notify friends"/>
      </ImageWrapper>
      <p>
        Check helps keep you safe by sending an alert to your emergency contact. 
        <br/><br/> Along with an link to watch the stop.
      </p>
    </Component>
  )

  const ConsolidatedInfoComponent = () => (
    <Component>
      <PrimaryHeading>
        All in one Place
      </PrimaryHeading>
      <InfoList>
        {/* License Plate */}
        <InfoComponent>
          <InfoImage>
            <img src={licensePlate} alt="License plate"/>
          </InfoImage>
          <br/>
          <InfoLabel>
            License Plate #
          </InfoLabel>
        </InfoComponent>

        {/* Drivers License Number */}
        <InfoComponent>
          <InfoImage>
            <img src={driversLicense} alt="Drivers License"/>
          </InfoImage>
          <br/>
          <InfoLabel>
            Drivers License
          </InfoLabel>
        </InfoComponent>

        {/* Insurance */}
        <InfoComponent>
          <InfoImage>
            <img src={insurance} alt="Insurance"/>
          </InfoImage>
          <br/>
          <InfoLabel>
            Insurance
          </InfoLabel>
        </InfoComponent>
      </InfoList>
      <p>
        Keep all your driving documents in one place. No more having to reach or struggle to find the info you need during a traffic stop.
      </p>
    </Component>
  )

  return (
    <>
      {
        isLoading || isAuthLoading ? (
          <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
            <Spinner strokeWidth={1} size={96} color="primary" />
          </Flex>
        ) : (
          <>
            <Carousel isRTL={false} itemsToShow={1} showArrows={false} renderPagination={props => <Pagination {...props} />} >
              <WelcomeComponent />
              <InformationSafteyComponent />
              <NotifyComponent />
              <ConsolidatedInfoComponent />
            </Carousel>
            <PrimaryButton style={{ marginTop: '30px' }} onClick={loginWithRedirect}>
              Get Started
            </PrimaryButton>
            <Button variant="ghost" sx={{ mt: 2, color: 'primary', py: 6 }} onClick={loginWithRedirect}>
              Login
            </Button>
          </>
        )
      }
      
    </>
  )
}

const Component = styled.div`
  margin-bottom: 20px;
  height: 100%;
`
const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const InfoComponent = styled.div`
  display: grid;
  grid-template-columns: 20% 10% 70%;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`

const InfoImage = styled.div`
  padding: 5px;
  width: 100%;
  > img {
    width: 100%;
    object-fit: contain;
  }
`
const InfoLabel = styled.h3``

export const ImageWrapper = styled.div`
  width: 100%;
  > img {
    width: 100%;
    object-fit: contain;
  }
`

export const Pagination = ({ activePage, onClick, pages }: RenderPaginationProps) => {

  const { theme } = useThemeUI()

  return (
    <Flex sx={{
      "> div": {
        mr: 3,
        "&:last-child": {
          mr: 0
        }
      }
    }}>
      {
        pages.map(page => {
          const isActivePage = activePage === page
          return (
            <Box key={page} onClick={() => onClick(page as any)} sx={{ 
              width: "14px",
              height: "14px",
              borderRadius: "50%",
              // background: isActivePage ? theme.colors?.primary : "white",
              background: "white",
              borderColor: "primary",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 200ms"
            }} />
          )
        })
      }
    </Flex>
  )
}