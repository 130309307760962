import styled from "@emotion/styled"

export const Dot = styled.div<{active: boolean}>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: ${props => props.active ? "#ddd" : "#f4f4f4"};
`

interface ProgressDotsProps {
  total: number
  activeIndex: number
}

export const ProgressDots: React.FC<ProgressDotsProps> = ({
  total,
  activeIndex
}) => {

  const dots = new Array(total).fill("").map((v, index) => {
    return <Dot active={activeIndex === index} key={index} />
  })

  return (
    <DotsGroup total={total}>
      {dots}
    </DotsGroup>
  )
}

export const DotsGroup = styled.div<{ total: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.total}, 16px);`};
  grid-gap: 8px;
  justify-content: center;
`