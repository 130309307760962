import React from 'react'
import { useForm } from "react-form"
import { Button, Grid, Heading, Spinner, Text } from "theme-ui"
import { Select } from '../foundations/Select'
import { statesAbbr } from '../utils/stateAbbreviations'
import { useInput } from '../utils/useInput'
import { TextInputField } from './ConnectedInputs'

export type EditVehicleInformationFormProps = {
  onSubmit: (values: any) => void,
  onCancel: () => void,
  defaultValues?: {
    plate: string
    state: string
    make: string
    model: string
    vin: string
    year: string
  }
}

export const EditVehicleInformationForm: React.FC<EditVehicleInformationFormProps> = ({ onSubmit, onCancel, defaultValues }) => {

  const {
    Form,
    meta: {
      isSubmitting,
      canSubmit,
    },
  } = useForm({
    onSubmit: async (values, { reset }) => {
      await onSubmit({
        ...values,
        state: statesAbbr.find(usState => usState.label === selectedState.value)?.value
      })
      reset()
    }
  })

  const defaultStateValue = defaultValues?.state
  const defaultStateLabel = statesAbbr.find(statePair => statePair.value === defaultStateValue)?.label
  const selectedState = useInput<string | null>(defaultStateLabel as string)

  return (
    <Form>
      <Grid gap={4}>
        <Heading variant="secondary">Vehicle Info Corrections</Heading>
        <Text>Be sure to validate your changes are correct.</Text>
        <TextInputField label="Plate" field="plate" defaultValue={defaultValues?.plate}/>
        <Select
          items={statesAbbr.map(value => value.label)}
          label="State"
          onSelectedItemChange={e => selectedState.setValue(e.selectedItem as string)}
          selectedItem={selectedState.value as string}
        />
        <TextInputField label="Make" field="make" defaultValue={defaultValues?.make}/>
        <TextInputField label="Model" field="model" defaultValue={defaultValues?.model}/>
        <TextInputField label="Vin" field="vin" defaultValue={defaultValues?.vin}/>
        <TextInputField label="Year" field="year" defaultValue={defaultValues?.year}/>

        <Grid columns="3fr 1fr">
          <Button sx={{ fontSize: 1 }} type="submit" disabled={!canSubmit}>
            { isSubmitting ? <Spinner strokeWidth={4} size={18} color="white"/> : "Submit"}
          </Button>
          <Button sx={{ fontSize: 1 }} type="button" bg="white" color="primary" onClick={onCancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Form>
  )
}