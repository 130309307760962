import config from "../../config"

interface MutationDeps {
  vehicleId: string,
  getAccessTokenSilently: () => Promise<string>
}

type MutationUpsertVehicleInfoOpts = {
  plate?: string
  state?: string
  engine?: string
  make?: string
  madeIn?: string
  model?: string
  trim?: string
  vin?: string
  year?: string
  id: string
}

export const mutationUpsertVehicleInfo = (deps: MutationDeps) => async function (opts: MutationUpsertVehicleInfoOpts) {
  console.log(deps.vehicleId)
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/upsert-vehicle`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      ...opts,
      id: deps.vehicleId
    })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}