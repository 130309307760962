import axios from 'axios'
// import config from "../../config"


export const grabSecretStripeUrl = async function () {
  
 return  axios({
    method: 'post',
    url: 'https://880d13hc1g.execute-api.us-west-1.amazonaws.com/dev/payment/url',
  })
  .then((response) => {
    console.log('CONSOLE.LOG',response.data.url)
    const url = response.data.url
    return url
  })
  .catch(function (error) {
    console.log(error);
  });

  // Save this as a constant
  // const URL = 'https://880d13hc1g.execute-api.us-west-1.amazonaws.com/dev/payment/url'
  // const fetchOpts: RequestInit = {
  //   method: "post",
  //   headers: {
  //     "Content-Type": "application/json",
  //   }
  // }
  // const response = await fetch(TEST_URL, fetchOpts)

  // const secretStripeUrl = await response.url
  // return secretStripeUrl
}


