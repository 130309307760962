import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Plea {
  value: string;
}
const initialState: Plea = {
  value: 'No plea has been entered',
};

export const pleaSlice = createSlice({
  name: "plea",
  initialState,
  reducers: {
    addPlea: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
});

export const { addPlea } = pleaSlice.actions;

export default pleaSlice.reducer;