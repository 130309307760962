import config from "../../config"

interface QueryActualTokenDeps {
  shortAlias: string
}

export const queryActualToken = (deps: QueryActualTokenDeps) => async () => {
  const url = `${config.api.url}/api/v1/drivers/query/find-actual-token/${deps.shortAlias}`
  const fetchOpts: RequestInit = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    }
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}