import React from 'react'

import shareTheMovementImage from "../../assets/step-illustrations/share-the-movement.svg"
import { PageLayout } from '../../components/Layout'
import { PrimaryHeading } from '../../components/PrimaryHeading'
import { ResponsiveImageWrapper } from '../../components/ResponsiveImageWrapper'
import { OutlineAnchorButton } from '../../components/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faLinkedin, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import styled from '@emotion/styled'
import { Heading } from '../../foundations/Text'
import { Grid } from '@theme-ui/components'


const _faLinkedin = faLinkedin as IconProp
const _faTwitter = faTwitter as IconProp
const _faFacebook = faFacebook as IconProp
const _faEnvelope = faEnvelope as IconProp

export const ShareSocialPage: React.FC<{}> = () => {
  return (
    <>
      <Grid gap={8}>
        <Heading textAlign="center">
            Share the Movement
        </Heading>
        <InputGroup>
          <SocialMediaButton 
            aria-label="Share to LinkedIn"
            target="_blank"
            href="https://www.linkedin.com/company/getcheckapp/"
          >
            <FontAwesomeIcon icon={_faLinkedin} size="2x"/>
            <span>
              LinkedIn
            </span>
          </SocialMediaButton>
          <SocialMediaButton
            aria-label="Share to Twitter"
            target="_blank"
            href="https://twitter.com/intent/tweet?text=Help create safer traffic stops! Sign-up for @get_check_app for free and help shape the future of traffic stops. #police #safetrafficstops #getcheckapp"
          >
            <FontAwesomeIcon icon={_faTwitter} size="2x"/>
            <span>
              Twitter
            </span>
          </SocialMediaButton>
          <SocialMediaButton
            aria-label="Share to Facebook" 
            target="_blank" 
            href="https://www.facebook.com/Getcheckapp-619261335170960"
          >
            <FontAwesomeIcon icon={_faFacebook} size="2x"/>
            <span>
              Facebook
            </span>
          </SocialMediaButton>
          <SocialMediaButton
            aria-label="Share with Email"
            target="_blank"
            href="mailto:?to=&subject=Help shape the future of traffic stops"
          >
            <FontAwesomeIcon icon={_faEnvelope} size="2x"/>
            <span>
              Email
            </span>
          </SocialMediaButton>
        </InputGroup>
        <ResponsiveImageWrapper>
          <img src={shareTheMovementImage} alt="Pls..."/>
        </ResponsiveImageWrapper>
      </Grid>
    </>
  )
}


const SocialMediaButton = styled(OutlineAnchorButton)`
  display: grid;
  grid-template-columns: 40px auto;
  justify-content: flex-start;
  text-transform: none;
  font-size: 18px;
  padding: 18px;
  > span {
    font-size: 28px;
    margin-left: 12px;
    padding-left: 16px;
    border-left: #4D66F2 thin solid;
    height: 100%;
  }
`

const InputGroup = styled.div`
  display: grid;
  grid-gap: 16px;
`

const ShareMessage = styled.p`
  text-align: center;
  font-size: 28px;
`