import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface SetMode {
  value: string;
}

const initialState: SetMode = {
  value: ''
};

export const driverLicenseSetModeSlice = createSlice({
  name: "driverLicenseSetMode",
  initialState,
  reducers: {
		setCurrentData: (state, action) => {
			state.value =  action.payload
		}
  },
});

export const { setCurrentData } = driverLicenseSetModeSlice.actions;

export default driverLicenseSetModeSlice.reducer;