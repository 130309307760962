import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { createContainer } from "unstated-next"


export enum RunTourState {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED"
}

export const RunTourContainer = createContainer(() => {

  const initRunTour = window.localStorage.getItem('run-tour') as RunTourState | null

  const [runTour, setRunTour] = useState<RunTourState>(initRunTour ?? RunTourState.ENABLED)

  useEffect(() => {
    window.localStorage.setItem('run-tour', runTour)
  }, [runTour])

  return {
    isAllowed: runTour === RunTourState.ENABLED,
    disable: () => setRunTour(RunTourState.DISABLED),
    enable: () => setRunTour(RunTourState.ENABLED)
  }

})