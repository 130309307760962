import config from "../../config"

interface MutationDeps {
  getAccessTokenSilently: () => Promise<string>
}

type MutationNotifyEmergencyContactsOpts = {}

export const mutationNotifyEmergencyContacts = (deps: MutationDeps) => async function (opts: MutationNotifyEmergencyContactsOpts) {
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/notify-emergency-contacts`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    // body: JSON.stringify({...opts})
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}