import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, SecondaryHeading, Text } from '../../foundations/Text'
import React from 'react'
import { DesignTokens } from "../../foundations/tokens"
import { Pill } from "../../foundations/Pill"
import { useTheme } from "@emotion/react"
import PDFGenerator, { GeneratePDF } from "../../components/PDFGenerator"
import SignatureInput from "../../components/SignatureInput"
import { useHistory } from 'react-router-dom'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

export const NoContestPleaPage: React.FC = () => {

  const theme = useTheme() as DesignTokens
  const history = useHistory()

  const citationNumber = "3220523"
  // const fullName = "Nicolas Fisher"
  //Bringing in user name
  const names = useSelector((state: RootState) => state.user.value)
  const firstName = names.map((name) => name.firstName)

  const handleClick = () => {
    GeneratePDF("innerContent")
    history.push("/no-contest-notice")
  }
  
  return (
    <PageLayout> 
      <PDFGenerator genId={"innerContent"}> 
      <FlexColumn gap={8}>
            <Heading textAlign="center">
            No Contest
            </Heading>
            <Pill>
            Citation #: <span style={{ fontWeight: "bold"}}>{citationNumber}</span>
            </Pill>
            <Text>
            I, <span style={{ fontWeight: "bold"}}>{firstName}</span>, do hereby enter
            my appearance on the complaint of the offense.
            </Text>
            <Text>
            Wit <span style={{ fontWeight: "bold"}}>{firstName} </span>
            charged in Municipal Court cause number given
            above. I have been informed of my right to a jury trial
            and that my signature to this plea of nolo contendere will have
            the same force and effect as a judgment of the Court. I
            do hereby plead nolo contenders to the said offense as charged,
            waive my right to a jury trial or hearing by the Court,
            and I agree to pay the fine and costs the judge
            assesses. 
            <br></br><br></br>
            <span style={{ color: theme.colors.primary }}>
                I understand that my plea may result in a
                conviction appearing on either a criminal record or a
                driverʼs license record.
            </span> 
            <br></br><br></br>
            </Text>
            <SecondaryHeading textAlign="center">
                SIGN
            </SecondaryHeading>
            <SignatureInput style={{ marginTop: '20px' }}/>
        <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
      </FlexColumn>
        </PDFGenerator>
    </PageLayout>
  )
}