import React, {useState} from 'react'
import { Box, Button, Grid, Heading, Image } from '@theme-ui/components'
// import { SecondaryButton, PrimaryButton } from '../../foundations/Buttons'
import {PrimaryButton} from '../../../../../foundations/Buttons'
import Camera from 'react-html5-camera-photo'
import { ActionCard } from '../../../../DashboardPage/components/ActionCard'
import { UploadDriversLicense } from './UploadDriversLicense'
import { useDispatch } from 'react-redux'
import { setCurrentData } from '../../../../../feature/driverLicenseSetModeSlice'
// import { useSelector } from 'react-redux'
// import { RootState } from '../../../../../store'

export function ViewDriversLicenseSectionv2() {
	// const [state, setState] = useState('INIT')
	//If Image already exist
	//const driversLicense = fn()

	const dispatch = useDispatch()

	
	const onClickHandler = () => {
		console.log('HANDLER')
		return dispatch(setCurrentData('EDIT'))
	}

	return (
		<Grid gap={4}>
			<Heading variant="secondary">
				License
			</Heading>
			<Box sx={{
				borderRadius: "8px",
				overflow: "hidden"
			}}>
				{<Image
					sx={{
						display: "block"
					}}
					src='https://m.media-amazon.com/images/I/71xpUOAu-PL._AC_SL1500_.jpg'
				/>}
			</Box>
				<PrimaryButton onClick={() => onClickHandler()}>
					Update
				</PrimaryButton>
		</Grid>
	)
}