



import React from 'react'
import { EditVehicleInformationForm } from '../../../../../forms/EditVehicleInformationForm'
import { VehicleCompositionMode, VehicleInfoSectionContainer } from "../VehicleInfoSection"

export const EditVehicleInformation = () => {

  const { upsertVehicleInfoMutation, setVehicleCompositionMode, data } = VehicleInfoSectionContainer.useContainer()

  const hasVehicle = data.vehicles?.length > 0

  return (
    <EditVehicleInformationForm
      onSubmit={async values => {
        upsertVehicleInfoMutation.mutate(values)
      }}
      onCancel={() => {
        setVehicleCompositionMode( hasVehicle ? VehicleCompositionMode.VIEW : VehicleCompositionMode.INIT)
      }}
      defaultValues={{
        make: data?.vehicles?.[0]?.make,
        model: data?.vehicles?.[0]?.model,
        plate: data?.vehicles?.[0]?.plate,
        state: data?.vehicles?.[0]?.state,
        vin: data?.vehicles?.[0]?.vin,
        year: data?.vehicles?.[0]?.year,
      }}
    />
  )
}
