import { useAuth0 } from '@auth0/auth0-react'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import React, {useState, useEffect} from 'react'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { Box, Button, Grid, Heading, Image, Spinner, Text } from '@theme-ui/components'
import { createContainer } from 'unstated-next'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import Camera from 'react-html5-camera-photo'
import { querySelf } from '../../../../api/queries/querySelf.query'
import { addDriversLicenseToStoreAndDB } from '../../../../api/mutations/addDriversLicenseV2.mutation'
import { addDriversLicense, addCurrentData, setCurrentData } from '../../../../feature/driverLicenseSlice'
import { ActionCard } from '../../../DashboardPage/components/ActionCard'
import { UploadDriversLicense } from './components/UploadDriversLicense'
import { ViewDriversLicenseSectionv2 } from './components/ViewDriversLicenseSection'
import { IntialStateDriversLicenseSectionv2 } from './components/InitialStateDriversLicense'
import { RootState } from '../../../../store'


export const DriversLicenseSectionv2 = () => {

	const status = useSelector((state: RootState) => state.driverLicenseSetMode.value)
	console.log('CHECK STATUSs', status)

	useEffect(() => {

	},[status])

	
	return (
		<Grid>
			{status == '' && <IntialStateDriversLicenseSectionv2/>}
			{status == 'INIT' && <IntialStateDriversLicenseSectionv2/>}
			{status == 'VIEW' && <ViewDriversLicenseSectionv2/>}
			{status == 'EDIT' && <UploadDriversLicense/>}
		</Grid>
	)
}