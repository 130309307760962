import { useAuth0 } from "@auth0/auth0-react"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { PageLayout } from "../../components/Layout"
import { mutationCreateUser } from "../../api/mutations/createUser.mutation"
import { toast  } from 'react-toastify'
import { querySelf } from "../../api/queries/querySelf.query"
import { Flex, Spinner } from "@theme-ui/components"
import { NewUserForm } from "../../forms/NewUserForm"
import { addBasicUserInfo } from "../../api/mutations/addBasicUserInfo.mutations"
import { useProtectedRoute } from "../../utils/useProtectedRoute"
import { useDispatch } from 'react-redux'
import { create } from '../../feature/userSlice'

export const NewUserPage: React.FC = () => {
  const dispatch = useDispatch()

  const { isAuthenticated, user, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0()
  const history = useHistory()
  useProtectedRoute()

  const saveNewUserMutation = useMutation(async () => {
    const accessToken = await getAccessTokenSilently()
    await mutationCreateUser({
      accessToken,
      phone: 'Placehlder'
    })
  }, {
    onSuccess: () => {
      refetch()
    },
    onError: (error: any) => {
      toast.error(error?.message ?? error ?? "Something went wrong!")
    }
  })

  const addBasicUserInfoMutation = useMutation(addBasicUserInfo({ getAccessTokenSilently }), {
    onSuccess: () => {
      toast.success("Congrats, you're all signed up! 😎")
      refetch()
    },
    onError: (error: any) => {
      toast.error(error?.message ?? error ?? "Something went wrong!")
    }
  })

  const { isLoading, refetch } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    retry: 1,
    onSuccess: (data) => {
            dispatch(create({
              firstName: data.firstName,
              lastName: data.firstName,
              email: data.email
            }))
      console.log({ data })
      if (data.firstName && data.lastName && data.email) {
        history.push('/dashboard')
      }
    },
    onError: () => {
      saveNewUserMutation.mutate()
    }
  })

  return (
    <>
      { isLoading || isAuthLoading ? (
        <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
          <Spinner strokeWidth={1} size={96} color="primary" />
        </Flex>
      ) : (
        <NewUserForm
        onSubmit={values => {
          addBasicUserInfoMutation.mutate(values)
          }}
        />
      )}
    </>
  )
}

