import React from "react";
import SignatureCanvas from "react-signature-canvas"

const SignatureInput = (props: { style: React.CSSProperties }) => {

    return (
        <div style={props.style}>
            <SignatureCanvas 
                backgroundColor='rgba(245,242,242,1)'
                ref={(ref) => { 
                    ref?.getCanvas().setAttribute("style", "border-radius: 20px; width: 100%; height: 200px;"); 
                }}
            />
        </div>
    )
}
export default SignatureInput