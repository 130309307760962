import config from "../../config"

interface MutationRemoveEmergencyContactDeps {
  getAccessTokenSilently: () => Promise<string>
}

interface MutationRemoveEmergencyContactOpts {
  phone: string,
  name: string
}

export const mutationRemoveEmergencyContact = (deps: MutationRemoveEmergencyContactDeps) => async function (opts: MutationRemoveEmergencyContactOpts) {
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/remove-emergency-contact`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      name: opts.name,
      phone: opts.phone
    })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}