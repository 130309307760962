import styled from "@emotion/styled"
import { selectColor, selectSpace } from "./tokens"

// export const Pill = styled.span`
//   color: white;
//   text-align: center;
// `

export const Pill = styled.span`
  padding: ${selectSpace(2)} ${selectSpace(4)};
  color: white;
  background: ${selectColor("primary")};
  border-radius: ${selectSpace(5)};
  text-align: center;
`