
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "@emotion/styled"
import { Link } from 'react-router-dom'
import { SecondaryButton } from "../../foundations/Buttons"
import { Card } from "../../foundations/Card"
import { selectColor, selectSpace } from "../../foundations/tokens"
import { Box, Grid, Button, Heading, Text, Image, Flex} from "@theme-ui/components"
import zIndex from "@material-ui/core/styles/zIndex"
import icon from "../../assets/checkbw.png"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#2F229D",
    borderRadius: "15px",
    boxShadow: "20px 20px 100px #2f229d",
  },
  title: {
    fontSize: 20,
    textAlign: "left",
    color: "#fff",
    fontWeight: 900,
    padding: "10px",
  },
  subtitle: {
    fontSize: 15,
    textAlign: "center",
    color: "#fff",
    fontWeight: 200,
    paddingBottom: "30px"
  },
  button: {
    fontSize: 15,
    fontWeight: 400,
    background: "#fff",
    color: "#2f229d",
    borderRadius: "10px",
    width: "90%",
    margin: "0 auto 10px",
    display: "block",
    marginBottom: "10",
    '&:hover': {
        backgroundColor: '#fff',
        borderColor: '#fff',
        boxShadow: 'none',
    },
  },
}))

interface ResolveCitationProps {
  onNotifyClick: () => any
  hasEmergencyContact: boolean
}

const ResolveCitation = ({ onNotifyClick, hasEmergencyContact }: ResolveCitationProps) => {
  return (
    <ResolveCitationCard>
      <Grid gap={8}>
        <Grid gap={4} sx={{ zIndex: 50 }}>
          <Heading sx={{ fontSize: 4 }}>
            Got Pulled Over?
          </Heading>
          <Text sx={{ fontSize: 2 }}>
            Use Check to livestream your traffic stop to your emergency contacts so they can keep tabs on you.
          </Text>
        </Grid>

        <Grid>

          {hasEmergencyContact &&
          <Button sx={{
            color: "primary",
            textTransform: "uppercase",
            py: 7,
            fontWeight: "300",
            fontSize: 4,
            bg: "black",
            zIndex: 25,
          }} variant="ghost" onClick={onNotifyClick}>
            <Flex sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
              <Image src={icon} sx={{width: "90%"}}/>
              <Box sx={{paddingTop:"10px", fontSize: "75%"}}>Start Call</Box>
            </Flex>
          </Button>}

          {!hasEmergencyContact &&
          <Button sx={{
            color: "primary",
            textTransform: "uppercase",
            py: 7,
            fontWeight: "300",
            fontSize: 4,
            bg: "white",
            zIndex: 25
          }} variant="ghost" as={props => <Link {...props} to={'/emergency-contacts'} />}>
            Enable Emergency Contacts
          </Button>}
          
          { hasEmergencyContact &&
            <Button sx={{
              bg: "white",
              color: "primary",
              textTransform: "uppercase",
              py: 5
            }} variant="ghost" as={props => <Link {...props} to={'/emergency-contacts'} />}>Manage Contacts</Button>
          }
        </Grid>
        
      </Grid >
    </ResolveCitationCard>
  )
}

const ResolveCitationCard = styled(Card)`
  background: ${selectColor('primary')};
  background: linear-gradient(86.74deg, #3626A7 0%, #3C28C9 100%);
  box-shadow: 0px 4px 4px rgba(135, 124, 211, 0.67);
  color: white;
  padding: ${selectSpace(6)};
`

export default ResolveCitation