import axios from "axios";

export const updateCitation = async function (dto: any) {

  const localUrl = 'http://localhost:3000/dev/add/data'
  return  axios({
    method: 'post',
    url: localUrl,
    data: JSON.stringify(dto),
  })
  .then((response) => {
    console.log('CONSOLE.LOGs',response.data)
    const data = response.data
    return data
  })
  .catch(function (error) {
    console.log(error);
  });
}
