import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Flex, Grid, Spinner } from '@theme-ui/components'
import { mutationUpsertDriversLicense } from '../../api/mutations/upsertDriversLicense.mutation'
import { querySelf } from '../../api/queries/querySelf.query'

import createYourDigitalLicenseImage from "../../assets/step-illustrations/create-your-digital-license.svg"
import { PageLayout } from '../../components/Layout'
import { PrimaryHeading } from '../../components/PrimaryHeading'
import { ProgressDots } from '../../components/ProgressDots'
import IDUpload, { ScanSuccessResults } from '../../components/registration/IDUpload'
import { ResponsiveImageWrapper } from '../../components/ResponsiveImageWrapper'

export const CreateYourDigitalLicensePage: React.FC<{}> = () => {

  const history = useHistory()

  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading, error: authError, loginWithRedirect } = useAuth0()
  let {
    isLoading,
    data,
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => toast.error(error?.message),
    onSuccess: (data) => {
      if (data?.driversLicense?.number) {
        history.push("/add-a-vehicle")
      }
    }
  })

  useEffect(() => {
    if (!isAuthenticated && !isAuthLoading) {
      history.push("/")
    }
  }, [isAuthLoading, isAuthenticated])
  
  const upsertDriversLicenseMutation = useMutation(mutationUpsertDriversLicense({ getAccessTokenSilently }), {
    onSuccess: () => {
      history.push("/add-a-vehicle")
    },
    onError: (error: any) => {
      toast.error(error?.message)
    }
  })

  const handleSuccess = async (data: ScanSuccessResults) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      sex: data.sex,
      dateOfBirth: data.dateOfBirth,
      driversLicense: {
        imageKey: "",
        number: data.documentNumber,
        dateOfExpiration: {
          day: data.expiryDate.day,
          month: data.expiryDate.month, 
          year: data.expiryDate.year
        },
        vehicleClass: data.vehicleClass
      }
    }
    await upsertDriversLicenseMutation.mutate(payload)
  }

  return (
    <>
      { isLoading || isAuthLoading ? (
        <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
          <Spinner strokeWidth={1} size={96} color="primary" />
        </Flex>
      ) : (
        <Grid sx={{ gap: 4 }} >
          <PrimaryHeading>
            Create Your Digital License
          </PrimaryHeading>
          <ResponsiveImageWrapper>
            <img src={createYourDigitalLicenseImage} alt="Create Your Digital License!"/>
          </ResponsiveImageWrapper>
          <p>
            Take a photo of your drivers license to generate your digital license.
          </p>
          {
            upsertDriversLicenseMutation.isLoading ? (
              <Flex>
                <Spinner strokeWidth={4} size={18} color="white"/>
              </Flex>
            ) : (
              <IDUpload
                onScanSuccess={handleSuccess}
                onFatalError={event => {
                  toast.error("Unable to load ID scanner.")
                }}
                onScanError={event => {
                  toast.error(event?.detail?.message)
                }}
                compact
              />
            )
          }
          <ProgressDots total={3} activeIndex={0} />
        </Grid>
      )}
    </>
  )
}


