import config from "../../config"

interface MutationAddBasicUserInfoDeps {
  getAccessTokenSilently: () => Promise<string>
}

interface MutationAddBasicUserInfoOpts {
  firstName: string,
  lastName: string,
  email: string,
}

export const addBasicUserInfo = (deps: MutationAddBasicUserInfoDeps) => async function (opts: MutationAddBasicUserInfoOpts) {
  let accessToken = await deps.getAccessTokenSilently()
  const url =`${config.api.url}/api/v1/drivers/commands/add-basic-user-info`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ ...opts })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}