import React from 'react'
import { Box, Button, Grid, Heading, Image, Spinner, Text } from '@theme-ui/components'
import Camera from 'react-html5-camera-photo'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { upsertInsuranceInfo } from "../../../../../api/mutations/upsertInsuranceInfo.mutation"
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { InsuranceSectionContainer, InsuranceSectionMode } from '../InsuranceSection'

export const UpsertInsuranceInfo = () => {

  const [imageBlob, setImageBlob] = useState<Blob | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const { isLoading: isAuthLoading, getAccessTokenSilently } = useAuth0()
  const { setMode, data, refetch } = InsuranceSectionContainer.useContainer()
  
  const handleOnTake = async (dataUri: any) => {
    const blob = await (await fetch(dataUri)).blob()
    setImageBlob(blob)
    setImageUrl(dataUri)
    console.log('[RODERICK] handleOnTake {Insurance} ===> ',{
      blob: imageBlob,
      image: imageUrl,
      data: dataUri
    })
  }

  const upsertInsuranceInfoMutation = useMutation(upsertInsuranceInfo({ getAccessTokenSilently }), {
    onSuccess: () => {
      setMode(InsuranceSectionMode.VIEW)
      refetch()
    },
    onError: (error: any) => {
      toast.error(error?.message)
    }
  })
  return (
    <Grid>
      <Heading variant="secondary">
        Take Photo
      </Heading>
      <Text>
        Capture your proof of insurance as legibly as possible.
      </Text>
      {    console.log('[RODERICK] Render {Insurance} ===> ',{
      blob: imageBlob,
      image: imageUrl,
      })}
      {
        !imageUrl ? (
          <Box sx={{
            borderRadius: "8px",
            overflow: "hidden",
            background: "black",
          }}>
            <Camera onTakePhoto={handleOnTake} idealFacingMode="environment" />
          </Box>
        ) : (
          <Grid>
            <Image src={imageUrl}/>
              <Button onClick={() => {
                if (imageBlob) {
                  upsertInsuranceInfoMutation.mutate({
                    insuranceCardImage: imageBlob
                  })
                }
              }} disabled={!imageBlob}>
                Confirm
              </Button>
              <Button
                variant="ghost"
                sx={{
                  color: "primary"
                }}
                onClick={() => {
                  setImageBlob(null)
                  setImageUrl(null)
                }}
              >
                Retake
              </Button>
          </Grid>
        )
      }
      
      <Button variant="ghost" sx={{
        color: "primary"
      }} onClick={() => {
        setMode(data?.insurance?.insuranceCardImageUrl ? InsuranceSectionMode.VIEW : InsuranceSectionMode.INIT)
      }}>
        Cancel
      </Button>
    </Grid>
  )
}