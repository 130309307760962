import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Citation {
	cNumber: string,
	courtStreetAdress: string,
	state: string,
	courtZipcode: string,
	county: string,
	citationType: string,
	precinct: string
}

export interface CitationState {
  value: Citation[];
}

const initialState: CitationState = {
  value: [],
};

export const citationSlice = createSlice({
  name: "citation",
  initialState,
  reducers: {
    addCitation: (state, action: PayloadAction<Citation>) => {
      state.value.push(action.payload);
    },
  },
});

export const { addCitation } = citationSlice.actions;

export default citationSlice.reducer;