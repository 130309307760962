import React, { useEffect, useState, VideoHTMLAttributes } from 'react'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { AspectRatio, Box, Button, Flex, Grid, Heading, Input, Spinner, Text } from '@theme-ui/components'
import { useInput } from '../../utils/useInput'
import queryString from 'query-string'
import { motion } from "framer-motion"

import { connect, RemoteTrack, RemoteParticipant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video"
import { useRef } from 'react'
import { queryActualToken } from '../../api/queries/queryActualToken.query'
import { useQuery } from 'react-query'


const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication?.track)
      .filter((track) => track !== null);


enum CallState {
  INIT = "INIT",
  LOADING = "LOADING",
  CONNECTED = "CONNECTED"
}

export const ContactCallPage = () => {

  const { search } = useLocation()
  const parsed = queryString.parse(search)
  const foundCode = parsed?.code as string ?? ""

  const {
    isLoading,
    data
  } = useQuery(['accessToken', foundCode], queryActualToken({ shortAlias: foundCode }))

  const [callState, setCallState] = useState<CallState>(CallState.INIT)

  const code = useInput<string>(foundCode)

  const handleClick = useCallback(async () => {
    await handleStartCall()
  }, [code])

  const headingText = `Hi ${parsed.name}!`

  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const [participant, setParticipant] = useState<RemoteParticipant>()

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  const handleStartCall = async () => {
    const room = await connect(data.actualToken, {
      audio: false,
      video: false
    })

    // make sure to render video track
    room.participants.forEach(participant => {
      console.log({ participant })
      setParticipant(participant)
    })

    // make sure to render video track
    room.on("participantConnected", participant => {
      console.log({ participant })
      setParticipant(participant)
    })
    room.on("participantDisconnected", value => console.log("participantDisconnected", value))
    room.on("participantReconnected", setParticipant)
    room.on("participantReconnecting", value => console.log("participantReconnecting", value))
    room.on("trackMessage", value => console.log("trackMessage", value))

    setCallState(CallState.LOADING)
  }

  useEffect(() => {
    console.log({ participant })
    if (participant) {
      setVideoTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.videoTracks)]);
      setAudioTracks(tracks => [...tracks, ...trackpubsToTracks(participant?.audioTracks)]);
  
      const trackSubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };
  
      const trackUnsubscribed = (track: RemoteTrack) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        }
      };
  
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
  
      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];

    setCallState((prevState) => {
      if (prevState === CallState.INIT) {
        return prevState
      }
      return videoTrack ? CallState.CONNECTED : CallState.LOADING
    })

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }


  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  console.log({ callState })


  return isLoading ? (
    <>
      <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
        <Spinner strokeWidth={1} size={96} color="primary" />
      </Flex>
    </>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Grid gap={4}>
        {
          callState === CallState.INIT && <>
            <Heading>
              {headingText}
            </Heading>
            {/* <Text>
              {instructionsText}
            </Text>
            <Input {...code.bind} p={4}/> */}
            <Button p={4} onClick={handleClick} disabled={code.value.length === 0}>
              Join Call
            </Button>
          </>
        }
        {
          callState === CallState.LOADING && <>
            <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
              <Spinner strokeWidth={1} size={96} color="primary" />
            </Flex>
          </>
        }
        <Grid sx={{
          opacity: callState === CallState.CONNECTED ? 1 : 0,
          transition: "opacity 200ms",
          gap: 4
        }}>
          <Heading sx={{ textAlign: "center" }}>
            You&apos;re watching {parsed.driver}!
          </Heading>
          <AspectRatio ratio={16 / 9} sx={{
            width: "100%",
            bg: "#333",
            borderRadius: "8px",
            overflow: "hidden",
            
            "> video": {
              height: "100%",
              width: "100%",
              objectFit: "contain"
            }
          }}>
            <video ref={videoRef} autoPlay={true}/>
          </AspectRatio>
        </Grid>
        <audio ref={audioRef} autoPlay={true} />
      </Grid>
    </motion.div>
  )
}