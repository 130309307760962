import { Box, FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { SecondaryButton, PrimaryButton } from '../../foundations/Buttons'
import { Select } from '../../foundations/Select'
import { Heading, SecondaryHeading, Text } from '../../foundations/Text'
import { TextInput } from '../../foundations/Input'
import { useInput } from '../../utils/useInput'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Card } from "../../foundations/Card"
import { selectSpace } from "../../foundations/tokens"
import { useHistory } from "react-router-dom"
import { loadStripe } from '@stripe/stripe-js';
// import { usePayment } from "../../store/data"
import { useMutation } from "react-query"
import { makePayment } from "../../api/mutations/makePayment.mutation"
// import {useStripeCustomerSecret} from '../../hooks/stripeHook'
import Stripe from 'stripe'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

export const BillPage: React.FC = () => {

  // Bringing in citation state
  const citations = useSelector((state: RootState) => state.citations.value)
  const address = citations.map((citation) => citation.courtStreetAdress)
  const citationNumber = citations.map((citation) => citation.cNumber)

  
  // Bringing in plea state
  const plea = useSelector((state: RootState) => state.pleas.value)
  
  //Bringing in No Contest Data
  const description = useSelector((state: RootState) => state.noContestChoice.value)
  
  //Bringing in secret stripe url
  const sUrl = useSelector((state: RootState) => state.secretUrl.value)

  //Bringing in user name
  const userValues = useSelector((state: RootState) => state.user.value)
  const firstName = userValues.map((name) => name.firstName)
  const lastName = userValues.map((name) => name.lastName)


  const history = useHistory()
  
  //const { setCitationID } = usePayment()
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      console.log("Order placed! You will receive an email confirmation.");
    }
    if (query.get("canceled")) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  
  const createSessionMutation = useMutation(makePayment, {
    onSuccess: (e) => {
      // useStripeCustomerSecret(secret)
      console.log('SECRETs', e)
    },
    onError: () => console.log('ERRORs')
  })
  
  const stripeHandler = async () => {
    console.log("BILL  URL", sUrl, description)
    window.open(`${sUrl}`, "_blank")
  }

  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <Heading textAlign="center">
          Court
        </Heading>
        <SecondaryHeading textAlign="center">
          <br></br>
          {address}
        </SecondaryHeading>    
        <CardSection>
        <SecondaryHeading textAlign="center">
            The State of Texas vs. {firstName} {lastName}
        </SecondaryHeading>
        </CardSection>
        <CardSection>
        <Text>
            Citation #: {citationNumber}
            <br></br>
            Plea: {plea}
            <br></br>
            Description: {description}
            <br></br>
        </Text>
        </CardSection>
        {/* <CardSection>
        <Text>
            Subtotal
            <br></br>
            Service Fee
            <br></br>
            Additional Fee
            <br></br>
        </Text>
        </CardSection> */}
        <CardSection>
        <Text>
            Status
        </Text>
        <SecondaryHeading textAlign="center">
            Drivers Safety course completion documents submitted to courts.
        </SecondaryHeading>
        </CardSection>
        {/* <CardSection>
        <Text>
            Total
        </Text>
        </CardSection> */}
        <PrimaryButton onClick={() => stripeHandler()}>Make Payment</PrimaryButton>
      </FlexColumn>
    </PageLayout>
  )
}

const CardSection = styled(FlexColumn)`
  border-bottom: #ddd thin solid;
  /* padding-top: ${selectSpace(6)}; */
  padding-bottom: ${selectSpace(6)};
  gap: ${selectSpace(4)};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`