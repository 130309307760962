import { configureStore } from "@reduxjs/toolkit"
import citationReducer from '../feature/citationSlice'
import pleaReducer from '../feature/pleaSlice'
import noContestDescriptionReducer from '../feature/noContestDescriptionSlice'
import addSecretUrl from '../feature/stripeSecretUrlSlice'
import addDriversLicense from "../feature/driverLicenseSlice"
import addCurrentData from "../feature/driverLicenseModeSlice"
import setCurrentData from "../feature/driverLicenseSetModeSlice"
import create from "../feature/userSlice"

export const store = configureStore({
  reducer: {
    citations: citationReducer,
    pleas: pleaReducer,
    noContestChoice: noContestDescriptionReducer,
    secretUrl: addSecretUrl,
    driverLicense: addDriversLicense, 
    driverLicenseMode: addCurrentData,
    driverLicenseSetMode: setCurrentData,
    user: create,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch