import { BaseResponse } from "@check/app-model"
import { ResponseType } from "axios"
import { getRequest } from ".."

export interface Auth0UserOpts {
  user: Record<string, any>
}

export const queryUser = async ({ user }: Auth0UserOpts) => {
  const url = "/users/" + user?.name
  const response = await getRequest<any>(url, {})
  return response
}