import React from 'react'
import { splitFormProps, useField, useForm } from "react-form"
import { Grid } from "@theme-ui/components"
import { PrimaryButton } from '../foundations/Buttons'
import { TextInput, TextInputProps } from '../foundations/Input'

export const TextInputField = React.forwardRef<any, TextInputProps & any>((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props)
  const {
    value,
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField(field, fieldOptions)
  const inputProps = getInputProps({ ref, ...rest })
  // `value ?? ""` is to prevent the issue where the text input goes from uncontrolled to controlled
  return (
    <TextInput {...inputProps} value={value ?? ""} label={props.label}/>
  )
})
TextInputField.displayName = "InputField"

