
import { Checkbox } from "../../foundations/Checkbox"
import { Combobox } from '../../foundations/Combobox'
import { FlexColumn } from '../../foundations/Layout'
import { PageLayout } from '../../components/Layout'
import { SecondaryButton, PrimaryButton } from '../../foundations/Buttons'
import { Select } from '../../foundations/Select'
import { Text } from '../../foundations/Text'
import { TextInput } from '../../foundations/Input'
import { useInput } from '../../utils/useInput'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
// import { useCitationState, useUserState } from "../../store/data"
import { useHistory } from "react-router-dom"
import { queryAllCourts } from "../../api/queries/queryAllCourts.query"
import { Court } from "@check/app-model"
import { updateCitation } from "../../api/mutations/updateCitation.mutation"
import { grabSecretStripeUrl } from "../../api/mutations/grabSecretStripeUrl.mutation"
import { useMutation, useQuery } from "react-query"
import { Image } from "../../foundations/Image"
import { querySignedPutUrl } from "../../api/queries/querySignedPutUrl.query"
import { useAuth0 } from "@auth0/auth0-react"
import { isEmpty } from 'ramda'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core"
import { useDispatch } from "react-redux"
// import { selectUser, USER_FETCHED } from ""
import { addCitation } from "../../feature/citationSlice"
import { addSecretUrl } from "../../feature/stripeSecretUrlSlice"
import { querySelf } from "../../api/queries/querySelf.query"
import { toast } from "react-toastify"
import { create } from "../../feature/userSlice"
import { Flex, Spinner } from "theme-ui"

const Camera = require('react-dom-camera')

export const UploadCitationPage: React.FC = () => {
  const dispatch = useDispatch()

  // Test
  // const userStateTest (selectUser)
  // const [name] = useState(userStateTest.name)

  // Outside Hooks
  // const {citationState, upsertCitationState } = useCitationState()
  // const {userState, fetchUser} = useUserState()
  const { isAuthenticated, user, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0()

  let {isLoading, data} = useQuery('self', querySelf({getAccessTokenSilently}), {
    enabled: isAuthenticated,
    onError: (error:any) => toast.error(error?.message),
    onSuccess: (data) => {
      console.log('[SelfQuery Citation Upload Page] ===> ', data)
      dispatch(create({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      }))
    }
  })
  
  // States for Form
  const [hasCitation, setHasCitation] = useState(true)
  const [hasImage, setHasImage] = useState(false)
  const [noCourt, setNoCourt] = useState(false)
  const [noCounty, setNoCounty] = useState(false)
  const [courts, setCourts] = useState<Court[]>([])
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const history = useHistory()
  
  // Data States
  const [citationImage, setCitationImage] = useState() 
  const [state, setGeoState] = useState("")
  const [county, setCounty] = useState("")
  const [citationType, setCitationType] = useState("")
  const [urlForStripe, setUrlForStripe] = useState("")
  const [citationFormData, setCitationFormData] = useState({
    cNumber: "",
    courtStreetAdress: "",
    // state: "", Add state to object
    courtZipcode: "",
    county: "",
    citationType: "",
    precinct: ""
  })

  const citationMutation = useMutation(updateCitation, {
    onSuccess:  data => {
      // (upsertCitationState as any)(data)
      console.log("====>", data)
      // dispatch(create({firstName: 'Roderick'}))
      const addedStateToCitationFormData = {...citationFormData, state: state}
      dispatch(addCitation(addedStateToCitationFormData))
      history.push("/choose-plea")
    },
    onError: console.error
  })

  const grabSecretStripeUrlMutation = useMutation(grabSecretStripeUrl, {
    onSuccess: (url) =>{
      console.log('SUCCESS', url)
      
      // Store the state of the Stripe secret
      dispatch(addSecretUrl(url))

      // Added the state value to the citation object
    
      history.push("/no-contest-notice")
      // citationMutation.mutate('addedStateToCitationFormData')
      },
    onError: () => console.log('ERRORS')
  })
  // const saveCitationImage = useMutation(saveToS3)

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }
  // let isAuthenticated = true
  useEffect(() => {
    // if (!isAuthenticated){
    //   history.push('/')
    // }
  })
    // useEffect(() => {
  //   updateCitations()

  //   if (isAuthenticated) {
  //     if(isEmpty(userState))
  //       fetchUser(user)
  //   }
  // }, [isAuthenticated, userState]);

  // const updateCitations = async () => {
  //   const c = await queryAllCourts()
  //   setCourts(c as Court[])
  // }

  // const _citationMutation = useMutation(async ({updateCitation}) => {
  //   const accessToken = await getAccessTokenSilently()
  //   return await updateCitation() => {
  //     d
  //   }
  // })

  const validated = () => {
    if(citationFormData.cNumber === "") {
      setMessage("Must have Citation Number")
      setOpen(true)
      return false
    }

    else if(citationFormData.courtStreetAdress === "") {
      setMessage("Must have Ticket Amount")
      setOpen(true)
      return false
    }

    else if(state === "") {
      setMessage("Must choose a State")
      setOpen(true)
      return false
    }

    else if(citationFormData.courtZipcode === "") {
      setMessage("Must have a Zip Code")
      setOpen(true)
      return false
    }

    // else if(citationFormData.county === "") {
    //   setMessage("Must have a County")
    //   setOpen(true)
    //   return false
    // }
   
    else if(citationFormData.precinct === "") {
      setMessage("Must have a Precint")
      setOpen(true)
      return false
    }

    // else if(citationFormData.citationType === "") {
    //   setMessage("Must select a Citation Type")
    //   setOpen(true)
    //   return false
    // }

    return true
  }

  const handleAddCitation = () => {
    if (validated()){
      // grabSecretStripeUrlMutation.mutate()
      citationMutation.mutate(citationFormData)
    }
  }

  const handleCitationImage = (image: any) => {
    setCitationImage(image)
    setHasImage(true)
  }

  //const state = useInput<string>("")
  const states = [
  "",
  "Texas"
  ]
  
  //const citationType = useInput<string>("")
  const citationTypes = [
    "Speeding",
    "Red Light",
    "Stop Sign",
    "U-Turn",
    "Other",
  ]

  const counties = [
    "",
    "Travis County",
    "Bexar County"
  ]

  const ImageButton = ({ onClick }: any) => (
    <PrimaryButton onClick={onClick} compact>Upload Citation</PrimaryButton>
  )

  return (
    <>
    { isLoading || isAuthLoading ? (
        <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
          <Spinner strokeWidth={1} size={96} color="primary" />
        </Flex>
      ) : (
      <PageLayout>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </Snackbar> 
      <FlexColumn gap={8}>
        <SecondaryHeading style={{ textAlign: "center" }}>
          Hi <span style={{ fontWeight: "bold"}}>name</span> Upload Citation Photo
        </SecondaryHeading>
        <FlexColumn gap={4}>
          { hasCitation && !hasImage &&
            <Camera
              onTakePhoto = { (image: any) => { handleCitationImage(image) } }
              captureButtonRenderer={(onClick: any) => <ImageButton onClick={onClick} />}
            /> 
          }
          { hasImage &&
            <>
              {/* <img src={citationImage} /> */}
              <Image src={citationImage} objectFit="contain" />
              <PrimaryButton onClick={() => setHasImage(false)} compact>Reupload Citation</PrimaryButton>
            </>
          }
          { !hasCitation && !hasImage &&
            <PrimaryButton onClick={() => setHasCitation(true)} compact>Upload Citation</PrimaryButton>
          }
          {/* <SecondaryButton onClick={() => { setHasCitation(false); setHasImage(false) }} compact>I don&apos;t have a copy of my ticket</SecondaryButton> */}
        </FlexColumn>
        {/* <Text style={{ textAlign: "center" }}>And</Text> */}
        <FlexColumn gap={4}>
          <TextInput   
            label="Enter Citation Number" 
            value = {citationFormData.cNumber}
            onChange = {(e) => setCitationFormData({...citationFormData, cNumber: e.target.value})}
          />
          <TextInput   
            label="Enter Court Street Address" 
            value = {citationFormData.courtStreetAdress}
            onChange = {(e) => setCitationFormData({...citationFormData, courtStreetAdress: e.target.value})}
          />
          <TextInput   
            label="Enter Court Zipcode" 
            value = {citationFormData.courtZipcode}
            onChange = {(e) => setCitationFormData({...citationFormData, courtZipcode: e.target.value})}
          />
          <Select
            items={states}
            label="Choose State"
            selectedItem={state}
            onSelectedItemChange={(e) => setGeoState(e.selectedItem ?? "")}/>
        </FlexColumn>
        <FlexColumn gap={4}>
          <Text>Choose the county that citation was issued in.</Text>
          <Combobox
            items={counties}
            selectedItem={county}
            onSelectedItemChange={(e) => setCounty(e.selectedItem ?? "")}
            placeholder="Ex: Travis County" />
        </FlexColumn>
        <FlexColumn>
          <Text>Choose the Precinct</Text>
          <TextInput   
            label="Enter Precinct" 
            value = {citationFormData.precinct}
            onChange = {(e) => setCitationFormData({...citationFormData, precinct: e.target.value})}
          />
        </FlexColumn>
        <Select
          items={citationTypes}
          label="Choose Citation Type"
          selectedItem={citationType}
          onSelectedItemChange={(e) => setCitationType(e.selectedItem ?? "")}/>
          <FlexColumn paddingY={5}>
            <PrimaryButton onClick={handleAddCitation}>Send Citation Details</PrimaryButton>
          </FlexColumn>
      </FlexColumn>
    </PageLayout>
      )}
    </>
  )
}

const SecondaryHeading = styled(Text)`
  font-weight: bold;
`

