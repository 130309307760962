import dotenv from "dotenv"
dotenv.config()

interface Config {
  env: string
  api: {
    url: string
  }
  auth: {
    domain: string
    clientId: string
    audience: string
    baseNameSpace: string
  }
  blinkIdKey: string
  isProd: boolean
  tagManager: {
    analyticsId: string
  }
  rapidapi: {
    host: string
    key: string
  }
}

const defaults = {
  env: "development",
  api: {
    url: process.env.REACT_APP_API_URL ?? "https://api.dev.getcheckapp.com",
  },
  blinkIdKey: process.env.REACT_APP_BLINKID_KEY ?? "sRwAAAYbZHJpdmVycy5kZXYuZ2V0Y2hlY2thcHAuY29t5gy156kxzd6erE3RzGXebc9dbYeg5iUmT1cKN8CRQh1doE+JJsw2ifCTqvzQi3ctXFXLU5lHAB/eCf1KysA2jcY2TafpWhHLYyrrzlf5m/G5sic3ll/bFP1ussotA6tOd+wzihVG0A+DpVW8rsix9mx72Sb5LWAE1gHKKv97NSQL0/oXdSmr5Dw7PWSWD3Wf4VTbpMHPE1knaS2ulr0RXM5af2HoznVE1in+LAx19sdXfTX+/jeoBzc5OjwRlPAouGQ/kwpVYgyCatGBq6B2eQ6Gu+imehkoMbasrGiSO7nRRPz8c2tmIPqfY1dwirQ8FcbNuxOPFu7tw+1agDNijOrc",
  auth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? "dev-pllg2po5.us.auth0.com",
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? "u1ZpEMkcJIaJ6hmecX8hcM7BK2vsIhcr",
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? "https://api.getcheckapp.com/",
    baseNameSpace: process.env.REACT_APP_AUTH_BASE_NAMESPACE ?? "https://api.getcheckapp.com",
  },
  tagManager: {
    analyticsId: process.env.REACT_APP_TAG_MANAGER ?? ""
  },
  rapidapi: {
    host: process.env.REACT_APP_RAPID_HOST || "us-license-plate-to-vin.p.rapidapi.com",
    key: process.env.REACT_APP_RAPID_KEY || "d9b372aa33msh467e98b1d8dbd72p12d33fjsneb9cffdd807e",
  },
}

const isProd = process.env.NODE_ENV === "production"

console.log("APIURL", process.env.REACT_APP_API_URL)

const config = {
  env: isProd ? "production" : defaults.env,
  isProd: isProd,
  blinkIdKey: defaults.blinkIdKey,
  auth: {
    domain: process.env.AUTH_DOMAIN ?? defaults.auth.domain,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? defaults.auth.clientId,
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? defaults.auth.audience,
    baseNameSpace: process.env.REACT_APP_AUTH_BASE_NAMESPACE ?? defaults.auth.baseNameSpace,
  },
  api: {
    url: process.env.REACT_APP_API_URL ?? defaults.api.url,
  },
  tagManager: {
    analyticsId: process.env.REACT_APP_TAG_MANAGER ?? defaults.tagManager.analyticsId,
  },
  rapidapi: {
    host: defaults.rapidapi.host,
    key: defaults.rapidapi.key,
  }
}

export default config as Config
