import config from "../../config"

interface MutationAddEmergencyContactOpts {
  accessToken: string
  phone: string,
  name: string
}

export const mutationAddEmergencyContact = async function (opts: MutationAddEmergencyContactOpts) {
  const url =`${config.api.url}/api/v1/drivers/commands/add-emergency-contact`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${opts.accessToken}`,
    },
    body: JSON.stringify({
      name: opts.name,
      phone: opts.phone
    })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}