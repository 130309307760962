import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { DesignTokens, selectSpace } from "./tokens"

interface BoxProps {
  paddingY?: number
  paddingX?: number
  padding?: number
  theme?: DesignTokens
}

export const Box = styled.div<BoxProps>`
  padding: ${props => props.padding ? props.theme.space[props.padding] : ''};
  ${props => props.paddingY ? css`
    padding-top: ${props.theme.space[props.paddingY]};
    padding-bottom: ${props.theme.space[props.paddingY]};
  ` : css``}
  ${props => props.paddingX ? css`
  padding-top: ${props.theme.space[props.paddingX]};
  padding-bottom: ${props.theme.space[props.paddingX]};
` : css``}

`

export const FlexColumn = styled(Box)<{ gap?: number } & BoxProps>`
  display: grid;
  align-content: "flex-start";
  gap: ${props => selectSpace(props.gap ?? 0)(props)};
`