import React from 'react'
import styled from '@emotion/styled'

export const SecondaryHeading = styled.h3`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  color: #2F2E41;
`

