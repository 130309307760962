import { useState } from 'react'

export function useInput<T>(initValue: T) {

  const [value, setValue] = useState<T>(initValue)

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (e: any) => setValue(e.target.value)
    }
  }
}