import { FlexColumn } from '../../foundations/Layout'
import { Checkbox } from "../../foundations/Checkbox"
import { PageLayout } from '../../components/Layout'
import { PrimaryButton } from '../../foundations/Buttons'
import { Heading, Text } from '../../foundations/Text'
import React, { useState } from 'react'
import { Card } from "../../foundations/Card"
import { useHistory } from 'react-router-dom'


export const NoContestNoticePleaPage: React.FC = () => {
  const history = useHistory()
  const [didAcknowledge, setDidAcknowledge] = useState(false)
  
  const handleClick = async () => {
    if(didAcknowledge)
        // history.push("/no-contest-choice")
        history.push("/choose-plea")
  }

  return (
    <PageLayout> 
      <FlexColumn gap={8}>
        <Card>
          <Heading textAlign="center">
            Driving Safety Course
          </Heading>
          <Text>
            The charge for this violation may be dismissed by successfully completing a driving safety course
            or a motorcycle operator training course. 
            <br></br><br></br>
            You will lose that right if you do not appear before court on or before your appearance date with
            your request to take the driving safety course or motorcycle operator training course.  
          </Text>
        </Card>
        <Card>
          <Heading textAlign="center">
            Deffered Disposition (Probation)
          </Heading>
          <Text>
            You may request Deffered Disposition, 
            but you must submit your request before the appearance date on your ticket
          </Text>
        </Card>
        <Card>
          <Heading textAlign="center">
            Warning
          </Heading>
          <Text>
            A second or subsequent conviction of an offense under the 
            Texas Motor Vehicle Safety Responsibility Act will result in the 
            suspension of your driverʼs license and motor vehicle registration 
            unless you file and maintain evidence of financial responsibility with 
            the Department of Public Safety for two years from the date of conviction. 
            The department may waive the requirement to file evidence of financial responsibility 
            if you file satisfactory evidence with the department showing that at the time this 
            citation was issued, the vehicle was covered by a motor vehicle liability insurance policy 
            or that you were otherwise exempt from the requirements 
            to provide evidence offfinancial responsibility.”
          </Text>
        </Card>
        <Checkbox label="I have read and acknowledge the information above." 
            checked={didAcknowledge}
            onChange={e => setDidAcknowledge(!didAcknowledge)}
        />
        <PrimaryButton disabled={!didAcknowledge} onClick={handleClick}>Next</PrimaryButton>
      </FlexColumn>
    </PageLayout>
  )
}
