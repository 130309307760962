import config from "../../config"

interface MutationGiveConsentOpts {
  driverToken: string
  driverInfo: string
  consent: boolean
}

export const giveConsentMutation = async (opts: MutationGiveConsentOpts) => {
  const url =`${config.api.url}/api/v1/police/stop/consent/give`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    //   "Authorization": `Bearer ${opts.accessToken}`,
    },
    body: JSON.stringify({
        data: {
            driverToken: opts.driverToken,
            driverInfo: opts.driverInfo,
            consent: opts.consent
        }
    })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}