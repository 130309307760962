import config from "../../config"
import { queryUserPlateInfo } from "../queries/queryUserPlateInfo.query";

interface MutationAddVehicleDeps {
  getAccessTokenSilently: () => Promise<string>
}

type MutationAddVehicleOpts = { 
  plate: string
  state: string
  // engine: string
  // make: string
  // madeIn: string
  // model: string
  // trim: string
  // vin: string
  // year: string
} 

type TempDTO = { 
  plate: string
  state: string
  engine: string
  make: string
  madeIn: string
  model: string
  trim: string
  vin: string
  year: string
} 

export const mutationAddVehicle = (deps: MutationAddVehicleDeps) => async function (opts: MutationAddVehicleOpts) {
  let accessToken = await deps.getAccessTokenSilently()
  const vehicleData = await queryUserPlateInfo({ 
    plate: opts.plate,
    state: opts.state
  });
  const url =`${config.api.url}/api/v1/drivers/commands/add-vehicle`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      plate: vehicleData?.plate,
      state: vehicleData?.state,
      engine: vehicleData?.specifications?.engine,
      make: vehicleData?.specifications?.make,
      madeIn: vehicleData?.specifications?.made_in,
      model: vehicleData?.specifications?.model,
      trim: vehicleData?.specifications?.trim,
      vin: vehicleData?.specifications?.vin,
      year: vehicleData?.specifications?.year,
    } as TempDTO)
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}