import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { PageLayout } from '../../components/Layout'
import { PrimaryHeading } from '../../components/PrimaryHeading'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    minWidth: "20em"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    maxWidth: 260,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
    },
  imageIcon: {
    padding: 10,
  },
  iconRoot: {
    textAlign: "center",
  },
  iconGrid: {
    textAlign: "center",
    padding: 10,
  },
}))

export const NotGuiltyPage: React.FC<{}> = () => {
  const classes = useStyles()
  return (
    // <div className={classes.root}>
    // <Container component="main" maxWidth="xs">
    //   <Typography className={classes.title} color="textPrimary" gutterBottom>
    //      <b>Request Court Date</b>
    //      <br></br>
    //      Call (Number) to request a court date or visit...
    //   </Typography>
    // </Container>
    // </div>
    <PageLayout>
    <PrimaryHeading>
      Request Court Date
    </PrimaryHeading>
    <Typography className={classes.title} color="textPrimary" gutterBottom>
          Call (Number) to request a court date or visit...
    </Typography>
  </PageLayout>
  )
}
