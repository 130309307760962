import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Url {
  value: string;
}
const initialState: Url = {
  value: '',
};

export const stripeSecretUrlSlice = createSlice({
  name: "StripesUrl",
  initialState,
  reducers: {
    addSecretUrl: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
});

export const { addSecretUrl } = stripeSecretUrlSlice.actions;

export default stripeSecretUrlSlice.reducer;