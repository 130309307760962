import React from 'react'
import { Box, Button, Grid, Heading, Image } from '@theme-ui/components'
import { faIdBadge } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux'
import { ActionCard } from '../../../../DashboardPage/components/ActionCard'
import { setCurrentData } from '../../../../../feature/driverLicenseSetModeSlice'

export const IntialStateDriversLicenseSectionv2 = () => {
	const dispatch = useDispatch()

	const onClickHandler = () => {
		console.log('[RODERICK] ===> onClickHandler')
		return dispatch(setCurrentData('EDIT'))
	}

	return(
			<ActionCard
			icon={faIdBadge}
			stepText="Step 1"
			title="Drivers License"
			buttonText="Add Drivers License"
			details="Complete your Digital Drivers Card by adding your drivers license info."
			onClick={() => {
				onClickHandler()
			}}
		/>
	) 
}
