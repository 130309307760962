import { useAuth0 } from '@auth0/auth0-react'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { setMode } from '@microblink/blinkid-in-browser-sdk/ui/dist/types/stencil-public-runtime'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { Spinner } from '@theme-ui/components'
import { createContainer } from 'unstated-next'
import { querySelf } from '../../../../api/queries/querySelf.query'
import { ActionCard } from '../../../DashboardPage/components/ActionCard'
import { InsuranceInfoView } from './components/InsuranceInfoView'
import { UpsertInsuranceInfo } from './components/UpsertInsuranceInfo'

export enum InsuranceSectionMode {
  UPSERT = "UPSERT",
  VIEW = "VIEW",
  LOADING = "LOADING",
  INIT = "INIT"
}

export const InsuranceSection = () => {
  const {
    mode,
    setMode
  } = InsuranceSectionContainer.useContainer()
  switch (mode) {
    case InsuranceSectionMode.UPSERT: {
      return <UpsertInsuranceInfo />
    }
    case InsuranceSectionMode.VIEW: {
      return <InsuranceInfoView />
    }
    case InsuranceSectionMode.LOADING: {
      return <Spinner />
    }
    case InsuranceSectionMode.INIT: {
      return <ActionCard
        icon={faPaperclip}
        stepText="Step 3"
        title="Proof of Insurance"
        details="Add your proof of insurance to complete your profile!"
        buttonText="Add Insurance"
        onClick={() => {
          setMode(InsuranceSectionMode.UPSERT)
        }}
      />
    }
  }
}

export const InsuranceSectionContainer = createContainer(() => {

  const [mode, setMode] = useState<InsuranceSectionMode>(InsuranceSectionMode.LOADING)
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0()

  let {
    isLoading,
    data,
    refetch
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => toast.error(error?.message)
  })

  const isDataLoading = isLoading || isAuthLoading
  
  useEffect(() => {
    if (!isDataLoading && mode !== InsuranceSectionMode.UPSERT) {
      if (data?.insurance?.insuranceCardImageUrl) {
        setMode(InsuranceSectionMode.VIEW)
      } else {
        setMode(InsuranceSectionMode.INIT)
      }
    }
  }, [data, isDataLoading])

  return {
    mode,
    setMode,
    isDataLoading,
    data,
    refetch
  }
})