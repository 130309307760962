import React from 'react'
import { useForm } from "react-form"
import { Button, Grid, Heading, Spinner, Text } from "theme-ui"
import { TextInputField } from './ConnectedInputs'

export type NewUserFormProps<T> = {
  onSubmit: (values: T) => void,
  defaultValues?: Partial<T>
}

export const NewUserForm: React.FC<NewUserFormProps<{
  firstName: string,
  lastName: string
  email: string
}>> = ({ onSubmit, defaultValues }) => {

  const {
    Form,
    values,

    meta: {
      isSubmitting,
      canSubmit,
      fieldsAreValid
    },
  } = useForm({
    onSubmit: async (values, { reset }) => {
      await onSubmit(values as any)
      reset()
    }
  })

  const isDisabled = !(values?.firstName && values?.lastName && values?.email)

  return (
    <Form>
      <Grid gap={10}>
        <Grid gap={4}>
          <Heading>Let&apos;s get to know you!</Heading>
          <Text>Provide the following info so that we can set you up for safer traffic stops!</Text>
        </Grid>
        <Grid gap={4}>
          <TextInputField label="First Name" field="firstName" required defaultValue={defaultValues?.firstName} />
          <TextInputField label="Last Name" field="lastName" required defaultValue={defaultValues?.lastName}/>
          <TextInputField label="Email" field="email" type="email" required defaultValue={defaultValues?.email}/>
        </Grid>
        <Grid>
          <Button sx={{ fontSize: 1 }} py={5} type="submit" disabled={isDisabled}>
            { isSubmitting ? <Spinner strokeWidth={4} size={18} color="white"/> : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}