import { PageLayout } from '../../components/Layout'
import { PrimaryHeading } from '../../components/PrimaryHeading'
import { Button } from '../../components/Button'
import styled from '@emotion/styled'
import { useInput } from '../../utils/useInput'
import { TextField } from '@material-ui/core'
// import { useUserState, useVehicleDetails } from '../../store/data'
import { useEffect } from 'react'
import { ProgressDots } from '../../components/ProgressDots'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { updateUserVehicle } from '../../api/mutations/updateUserVehicle.mutation'

export const ConfirmVehicleDetailsPage: React.FC<{}> = () => {

  // const { vehicleDetails, upsertVehicleDetails, state, licensePlate } = useVehicleDetails()
  // const { userState } = useUserState()
  const history = useHistory()
  
  const userVehicleMutation = useMutation(updateUserVehicle, {
    onSuccess: data => {
      // upsertVehicleDetails(data)
      history.push("/dashboard")
    }
  })
  
  const make = useInput<string>("")
  const model = useInput<string>("")
  const vin = useInput<string>("")
  const year = useInput<string>("")

  // useEffect(() => {
  //   // const data = vehicleDetails
  //   make.setValue(data?.specifications?.make)
  //   model.setValue(data?.specifications?.model)
  //   vin.setValue(data?.specifications?.vin)
  //   year.setValue(data?.specifications?.year)
  // }, [vehicleDetails])

  const handleClick = () => {
    // userVehicleMutation.mutate({
    //   make: make.value,
    //   model: model.value,
    //   plate: licensePlate,
    //   userState: 'state',
    //   vin: vin.value,
    //   state,
    //   year: year.value,
    //   name: ""
    // })
  }

  return (
    <PageLayout>
      <PrimaryHeading>
        Confirm Vehicle Details
      </PrimaryHeading>
      <InputGroup>
        <TextField
          id="make"
          label="Make"
          variant="outlined"
          {...make.bind}
        />
        <TextField
          id="model"
          label="Model"
          variant="outlined"
          {...model.bind}
        />
        <TextField
          id="vin"
          label="Vin"
          variant="outlined"
          {...vin.bind}
        />
        <TextField
          id="year"
          label="Year"
          variant="outlined"
          {...year.bind}
        />
      </InputGroup>
      {
        userVehicleMutation.isLoading ? (
          <p>Saving...</p>
        ) : (
          <Button onClick={handleClick}>
            Complete Registration
          </Button>
        )
      }
      <ProgressDots total={3} activeIndex={2} />
    </PageLayout>
  )
}

const Label = styled.label`
  display: block;
  > span {
    font-size: 12px;
    letter-spacing: 0.05rem;
    margin-left: 2px;
    margin-bottom: 4px;
    display: block;
  }
`

const InputGroup = styled.div`
  display: grid;
  grid-gap: 16px;
`