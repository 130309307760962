/* eslint-disable react/display-name */
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { toast } from 'react-toastify'
import { querySelf } from "../../api/queries/querySelf.query"
import { Flex, Grid, Heading, Spinner, Text } from "theme-ui"
import { useProtectedRoute } from "../../utils/useProtectedRoute"
import { DriversLicenseSection, DriversLicenseSectionContainer } from "./components/DriversLicenseSection/DriversLicenseSection"
import { VehicleInfoSectionContainer, VehicleInfoSection } from "./components/VehicleInfoSection/VehicleInfoSection"
import { InsuranceSection, InsuranceSectionContainer } from "./components/InsuranceSection/InsuranceSection"
import { DriversLicenseSectionv2 } from "./components/DriversLicenseSectionv2/DriversLicenseSectionv2"
import React, { useEffect } from "react"

export const ProfilePage: React.FC = () => {

  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0()
  let {
    isLoading,
    data,
    refetch
  } = useQuery('self', querySelf({ getAccessTokenSilently }), {
    enabled: isAuthenticated,
    onError: (error: any) => toast.error(error?.message)
  })

  useProtectedRoute()

  const showSpinner = isLoading || isAuthLoading

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {
        showSpinner ? <>
          <Flex sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center' }} >
            <Spinner strokeWidth={1} size={96} color="primary" />
          </Flex>
        </> : <>
            <Grid gap={4}>
              <Grid gap={4}>
                <Heading>
                  This is your Digital ID
                </Heading>
                <Text>
                  Having this information quick and ready may make your traffic stop just that bit quicker and potentially safer.
                </Text>
              </Grid>
              <Grid gap={8}>
                <DriversLicenseSectionv2/>
                {/* <DriversLicenseSectionContainer.Provider>
                  <DriversLicenseSection />
                </DriversLicenseSectionContainer.Provider> */}
                <VehicleInfoSectionContainer.Provider>
                  <VehicleInfoSection />
                </VehicleInfoSectionContainer.Provider>
                <InsuranceSectionContainer.Provider>
                  <InsuranceSection />
                </InsuranceSectionContainer.Provider>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}

