import React from 'react'
import styled from '@emotion/styled'

export const ResponsiveImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`
