import { faIdCard, IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import React from 'react'
import { Card, Button, Heading, Text, Box, Flex } from '@theme-ui/components'
import {PrimaryButton} from '../../../foundations/Buttons'

interface ActionCardProps {
  icon: IconDefinition,
  stepText: string,
  title: string,
  details: string
  buttonText: string,
  onClick: () => void | Promise<void>
}

export const ActionCard = (props: ActionCardProps) => {
  const propsIcon = props.icon
  const _propsIcon = propsIcon as IconProp
  return <Card sx={{
    borderColor: "primary",
    borderWidth: "thin",
    borderStyle: "solid",
    display: "grid",
    gap: 4
  }}>

    <Flex sx={{ color: "primary", fontSize: 3, alignItems: "center" }}>
      <FontAwesomeIcon icon={_propsIcon}/>
      <Text ml={3} sx={{ fontSize: 1, fontWeight: 700, textTransform: "uppercase", letterSpacing: "0.06rem" }}>
        {props.stepText}
      </Text>
    </Flex>
    <Heading color="primary">
      {props.title}
    </Heading>
    <Text>
      {props.details}
    </Text>
    <PrimaryButton
      sx={{
        py: 5,
        fontWeight: 500,
        textTransform: "uppercase",
        letterSpacing: "0.05rem",
      }}
      onClick={props.onClick}
    >
      {props.buttonText}
    </PrimaryButton>
  </Card>
}