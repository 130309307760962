import config from "../../config"

interface MutationCreateUserOpts {
  accessToken: string
  phone: string
}

export const mutationCreateUser = async function (opts: MutationCreateUserOpts) {
  const url =`${config.api.url}/api/v1/drivers/commands/create-user`
  const fetchOpts: RequestInit = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${opts.accessToken}`,
    },
    body: JSON.stringify({
      phone: opts.phone
    })
  }
  const response = await fetch(url, fetchOpts)
  const data = await response.json()
  if (!response.ok) {
    throw data
  }
  return data
}